import classNames from 'classnames';
import { FunctionComponent, useMemo } from 'react';

const TARIF_TYPES = [
    'onglerie',
    'soins-du-visage',
    'epilation',
    'beaute-du-regard',
    'microblading',
    'maquillage',
] as const;
type TarifType = (typeof TARIF_TYPES)[number];

interface Tarif {
    [section: string]: {
        list: {
            name: string;
            price: string;
            description?: string;
        }[];
        subTitle?: string;
        footer?: string;
    };
}

const TARIFS_DATA: { [key: string]: Tarif } = {
    onglerie: {
        'Pose de gel': {
            subTitle: 'Âge minimum requis : 18 ans',
            list: [
                { name: 'Sur ongles naturels', price: '50€' },
                { name: 'Remplissage (4-5 semaines)', price: '53€' },
                { name: 'Extensions chablons - naturel', price: '65€' },
                { name: 'Extensions chablons - couleur', price: '70€' },
                { name: 'Ongles rongés - naturel', price: '70€' },
                { name: 'Ongles rongés -  couleur', price: '75€' },
                { name: 'Décorations, chrome, strass, babycolor', price: "à partir d'1€/ongle" },
                { name: 'Supplément french ou babyboomer', price: '5€' },
                { name: 'Réparation ongle cassé', price: '2€' },
                { name: 'Réparation hors pose ou reconstruction ongle', price: '5€' },
                { name: 'Dépose* avec manucure et soin', price: '35€' },
            ],
        },
        'Pose de semi permanent': {
            subTitle: 'Âge minimum requis : 16 ans avec accord parental écrit',
            list: [
                { name: 'Naturel ou couleur mains ou pieds', price: '35€' },
                { name: 'Dépose + repose', price: '39€' },
                { name: 'Supplément renfort semi permanent', price: '10€' },
                { name: 'Décorations, chrome, strass, babycolor', price: "à partir d'1€/ongle" },
                { name: 'Supplément french ou babyboomer', price: '5€' },
                { name: 'Réparation ongle cassé', price: '2€' },
                { name: 'Dépose* complète avec manucure et soin', price: '25€' },
            ],
            footer: '* déposes gel et semi permanent réalisées à la ponceuse, sans solution de fonte.',
        },
        'Soins traditionnels': {
            list: [
                {
                    name: 'Soins des ongles mains ou pieds',
                    description: '(Limage, traitement des cuticules, huile cuticules)',
                    price: '20€',
                },
                { name: 'Supplément base de vernis fortifiant', price: '5€' },
                {
                    name: 'Soin des pieds “Yumi feet”',
                    description: '(Traitement des callosités et des cuticules, limage des ongles, crème de soin)',
                    price: '45€',
                },
                { name: 'Soin “Yumi feet” + pose de semi permanent', price: '78€' },
            ],
        },
    },
    'soins-du-visage': {
        '': {
            list: [
                {
                    name: 'Soin du visage bio complet (1h)',
                    description:
                        'Démaquillage/nettoyage, gommage, bain de vapeur, traitement des comédons, modelage du visage/cou/décolleté aux huiles végétales bio, masque alginates, crème de soin.',
                    price: '65€',
                },
                {
                    name: 'Soin du visage bio express (50 min)',
                    description:
                        'Démaquillage/nettoyage, gommage, bain de vapeur, traitement des comédons, masque crème, crème de soin.',
                    price: '50€',
                },
            ],
        },
    },
    microblading: {
        '': {
            list: [
                { name: 'Création sourcils (retouche entre 4 et 6 semaines comprise)', price: '210€' },
                { name: 'Retouche 6 mois', price: '60€' },
                { name: 'Retouche annuelle', price: '100€' },
                { name: 'Retouche 18 mois', price: '150€' },
            ],
        },
    },
    maquillage: {
        '': {
            list: [
                { name: 'Maquillage express', price: '20€' },
                { name: 'Maquillage light', price: '29€' },
                { name: 'Maquillage sophistiqué', price: '39€' },
                { name: 'Maquillage mariée (essai inclu)', price: '50€' },
            ],
        },
    },
    'beaute-du-regard': {
        '': {
            list: [
                { name: 'Rehaussement de cils MISENLIFT', price: '55€' },
                { name: 'Forfait « rehaussement de cils + teinture des cils »', price: '65€' },
                { name: 'Teinture cils', price: '20€' },
                { name: 'Teinture sourcils', price: '18€' },
                { name: 'Forfait « épilation  sourcils + teinture »', price: '25€' },
            ],
        },
    },
    epilation: {
        '': {
            list: [
                { name: 'Demi-jambes', price: '18€' },
                { name: 'Trois-quarts jambes', price: '23€' },
                { name: 'Jambes entières', price: '28€' },
                { name: 'Aisselles', price: '13€' },
                { name: 'Maillot classique', price: '15€' },
                { name: 'Maillot échancré', price: '20€' },
                { name: 'Maillot semi-intégral', price: '25€' },
                { name: 'Maillot intégral', price: '29€' },
                { name: 'Bras', price: '15€' },
                { name: 'Sourcils', price: '12€' },
                { name: 'Dessus sourcils', price: '2€' },
                { name: 'Inter-sourcils', price: '4€' },
                { name: 'Lèvre ou menton', price: '8€' },
                { name: 'Cou ou joues', price: '4€' },
            ],
        },
        Forfaits: {
            list: [
                { name: 'Demi-jambes, aisselles, maillot classique', price: '39€' },
                { name: 'Demi-jambes, aisselles, maillot échancré', price: '43€' },
                { name: 'Demi-jambes, aisselles, maillot semi-intégral', price: '47€' },
                { name: 'Demi-jambes, aisselles, maillot intégral', price: '51€' },
                { name: 'Trois-quarts jambes, aisselles, maillot classique', price: '43€' },
                { name: 'Trois-quarts jambes, aisselles, maillot échancré', price: '47€' },
                { name: 'Trois-quarts jambes, aisselles, maillot semi-intégral', price: '51€' },
                { name: 'Trois-quarts jambes, aisselles, maillot intégral', price: '55€' },
                { name: 'Jambes entières, aisselles, maillot classique', price: '47€' },
                { name: 'Jambes entières,aisselles, maillot échancré', price: '51€' },
                { name: 'Jambes entières, aisselles, maillot semi-intégral', price: '55€' },
                { name: 'Jambes entières, aisselles, maillot intégral', price: '59€' },
                { name: 'Sourcils + lèvre ou lèvre + menton', price: '18€' },
                { name: 'Sourcils + lèvre + menton', price: '20€' },
            ],
        },
    },
};

interface Props {
    title: string;
    type: TarifType;
}

export const Tarifs: FunctionComponent<Props> = ({ title, type }: Props) => {
    // Tarifs
    const tarifs = useMemo(() => TARIFS_DATA[type], [type]);

    return (
        <div className="m-auto" style={{ maxWidth: 600 }}>
            {/* Title */}
            <h4 className="fw-bold mb-4">{title}</h4>

            {/* Tarifs */}
            <div>
                {Object.keys(tarifs).map((section) => {
                    const subTitle = tarifs[section].subTitle;
                    const list = tarifs[section].list;
                    const footer = tarifs[section].footer;

                    return (
                        <div key={section} className="mb-5">
                            {/* Section title */}
                            {section && (
                                <div className="mb-2">
                                    <h6 className="mb-0">
                                        <span>{section}</span>
                                    </h6>

                                    {subTitle && <span className="fs-sm fst-italic">{subTitle}</span>}
                                </div>
                            )}

                            {/* List */}
                            <ul className="ps-0">
                                {list.map((e, index) => (
                                    <li
                                        key={e.name}
                                        className={classNames('list-unstyled px-2 py-1', {
                                            'bg-grey': index % 2 === 0,
                                            'bg-white': index % 2 !== 0,
                                        })}
                                    >
                                        {/* Price line */}
                                        <div className="d-flex justify-content-between align-items-center">
                                            {/* Label */}
                                            <div className="me-2">{e.name}</div>

                                            {/* Price */}
                                            <div className="ms-2 text-nowrap">{e.price}</div>
                                        </div>

                                        {/* Description */}
                                        {e.description && <div className="mb-2 fs-sm fst-italic">{e.description}</div>}
                                    </li>
                                ))}
                            </ul>

                            {/* Footer */}
                            {footer && <div className="mt-4 fs-sm fw-bold fst-italic">{footer}</div>}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

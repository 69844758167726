import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import useInitializationQuery from '../../../api/hooks/useInitializationQuery';
import { GiftCard } from '../../../api/models/gift-card';
import { Button, Dropdown, Form, InputGroup, Spinner, Table } from 'react-bootstrap';
import { RichMessage } from '../../../components/RichMessage';
import moment from 'moment';
import { orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useIntl } from 'react-intl';
import GiftCardRow from './GiftCardRow';
import AddGiftCardModal from '../../../components/AddGiftCardModal/AddGiftCardModal';
import useQuery from '../../../api/hooks/useQuery';
import classNames from 'classnames';
import { useLocalStorage } from 'react-use';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { AsyncButton } from '../../../components/AsyncButton';

export const GiftCards: FunctionComponent = () => {
    // State
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedGiftCardIds, setSelectedGiftCardIds] = useState<string[]>([]);
    const [isArchivedHidden, setIsArchivedHidden] = useLocalStorage<boolean>('gift-cards.hide-archived', true);
    const [isAddGiftCardModalOpen, setIsAddGiftCardModalOpen] = useState<boolean>(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(false);
    const [actionInProgress, setActionInProgress] = useState<boolean>(false);

    // Services
    const intl = useIntl();

    // Fetch gift cards
    const {
        data: giftCards,
        loading,
        refetch,
    } = useInitializationQuery<GiftCard[]>('get', `/gift-cards${isArchivedHidden ? '?excludeArchived=true' : ''}`);

    // Mutations
    const archiveGiftCard = useQuery('patch', '/gift-cards/:id');
    const deleteGiftCard = useQuery('delete', '/gift-cards/:id');

    // Sorted gift cards
    const sortedGiftCards = useMemo(() => orderBy(giftCards, 'reference', 'desc'), [giftCards]);

    // Selected gift cards
    const selectedGiftCards = useMemo(
        () => giftCards?.filter((e) => selectedGiftCardIds.find((s) => s === e.id)) || [],
        [giftCards, selectedGiftCardIds]
    );

    // Filtered gift cards
    const filteredGiftCards = useMemo(
        () =>
            sortedGiftCards.filter(
                (e) =>
                    e.reference?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    e.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    e.sender.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    e.recipient.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    `${e.amount}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    moment(e.expirationDate).format('DD/MM/YYYY').toLowerCase().includes(searchTerm.toLowerCase())
            ),
        [searchTerm, sortedGiftCards]
    );

    // Hook invoked when a gift card has been created
    const handleGiftCardCreated = useCallback(() => {
        // Close modal
        setIsAddGiftCardModalOpen(false);

        // Refetch data
        refetch();
    }, [refetch]);

    // Hook invoked when a gift card is selected/unselected
    const handleSelectChange = useCallback(
        (selected: boolean, giftCard: GiftCard) => {
            // Abort if no gift card id
            if (!giftCard.id) return;

            if (selected && selectedGiftCardIds.indexOf(giftCard.id) < 0) {
                setSelectedGiftCardIds([...selectedGiftCardIds, giftCard.id]);
            } else if (!selected && selectedGiftCardIds.indexOf(giftCard.id) >= 0) {
                setSelectedGiftCardIds(selectedGiftCardIds.filter((e) => e !== giftCard.id));
            }
        },
        [selectedGiftCardIds]
    );

    // Hook invoked when gift cards are to be archived
    const handleArchiveGiftCards = useCallback(async () => {
        try {
            // Abort if an action is already in progress
            if (actionInProgress) return;

            // Set loading state
            setActionInProgress(true);

            // Request updates
            const res = await Promise.all(
                selectedGiftCardIds.map(async (e) => {
                    return await archiveGiftCard({ resourceIds: [e], body: { archived: true } });
                })
            );

            // If response was successful
            if (res.every((e) => !!e)) {
                // Reset loading state
                setActionInProgress(false);

                // Reset selection
                setSelectedGiftCardIds([]);

                // Refetch gift cards
                refetch();

                // Close modal
                setIsArchiveModalOpen(false);
            }
        } catch (error) {
            // Log error
            console.error(error);

            // Reset loading state
            setActionInProgress(false);
        }
    }, [actionInProgress, archiveGiftCard, refetch, selectedGiftCardIds]);

    // Hook invoked when gift cards are to be deleted
    const handleDeleteGiftCards = useCallback(async () => {
        try {
            // Abort if an action is already in progress
            if (actionInProgress) return;

            // Set loading state
            setActionInProgress(true);

            // Request deletions
            const res = await Promise.all(
                selectedGiftCardIds.map(async (e) => {
                    return await deleteGiftCard({ resourceIds: [e] });
                })
            );

            // If response was successful
            if (res.every((e) => !!e)) {
                // Reset loading state
                setActionInProgress(false);

                // Reset selection
                setSelectedGiftCardIds([]);

                // Refetch gift cards
                refetch();

                // Close modal
                setIsDeletionModalOpen(false);
            }
        } catch (error) {
            console.error(error);
        }
    }, [actionInProgress, selectedGiftCardIds, deleteGiftCard, refetch]);

    return (
        <>
            {/* Title */}
            <h4 className="fw-bold mb-4">
                <RichMessage id="gift-cards.title" />
            </h4>

            <div className="mb-3 d-flex justify-content-between align-items-center">
                {/* Filters */}
                <InputGroup className="flex-grow-1">
                    {/* Search icon */}
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>

                    {/* Input */}
                    <Form.Control
                        autoComplete="off"
                        placeholder={intl.formatMessage({ id: 'gift-cards.filters.search.placeholder' })}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </InputGroup>

                <div className="flex-grow-0 text-nowrap ms-2 d-flex gap-2">
                    {/* Add button */}
                    <Button onClick={() => setIsAddGiftCardModalOpen(true)}>
                        <FontAwesomeIcon className="me-2" icon={faPlus} />
                        <RichMessage id="gift-cards.add" />
                    </Button>

                    {/* Actions dropdown */}
                    <Dropdown>
                        <Dropdown.Toggle className="d-flex align-items-center">
                            <RichMessage id="gift-cards.manage" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="end">
                            {/* Archive */}
                            <Dropdown.Item
                                disabled={
                                    !selectedGiftCards.length || selectedGiftCards.some((e: GiftCard) => e.archived)
                                }
                                onClick={() => setIsArchiveModalOpen(true)}
                            >
                                <RichMessage id="gift-cards.archive" values={{ count: selectedGiftCardIds.length }} />
                            </Dropdown.Item>

                            {/* Delete */}
                            <Dropdown.Item
                                className={classNames({ 'text-danger': selectedGiftCards.length })}
                                disabled={!selectedGiftCards.length}
                                onClick={() => setIsDeletionModalOpen(true)}
                            >
                                <RichMessage id="gift-cards.delete" values={{ count: selectedGiftCardIds.length }} />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            {/* Hide archived toggle */}
            <div className="mb-3 d-flex justify-content-end">
                <Form.Check
                    type="switch"
                    label={intl.formatMessage({ id: 'gift-cards.filters.hide-archived.label' })}
                    checked={isArchivedHidden}
                    onChange={(e) => setIsArchivedHidden(e.target.checked)}
                />
            </div>

            {/* Gift cards list */}
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            ) : (
                <Table
                    bordered
                    striped={!!filteredGiftCards?.length}
                    hover={!!filteredGiftCards?.length}
                    style={{ fontSize: '1rem' }}
                >
                    <thead className="text-nowrap">
                        <tr>
                            <th></th>
                            <th>
                                <RichMessage id="gift-cards.table.th.reference" />
                            </th>
                            <th>
                                <RichMessage id="gift-cards.table.th.description" />
                            </th>
                            <th>
                                <RichMessage id="gift-cards.table.th.sender" />
                            </th>
                            <th>
                                <RichMessage id="gift-cards.table.th.recipient" />
                            </th>
                            <th>
                                <RichMessage id="gift-cards.table.th.expiration-date" />
                            </th>
                            <th>
                                <RichMessage id="gift-cards.table.th.amount" />
                            </th>
                            <th>
                                <RichMessage id="gift-cards.table.th.usage" />
                            </th>
                            <th>
                                <RichMessage id="gift-cards.table.th.details" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredGiftCards?.length ? (
                            filteredGiftCards?.map((e) => (
                                <GiftCardRow
                                    key={e.id}
                                    giftCard={e}
                                    selected={!!selectedGiftCardIds.find((s) => s === e.id)}
                                    onSelectChange={(selected) => handleSelectChange(selected, e)}
                                    onDelete={() => refetch()}
                                />
                            ))
                        ) : (
                            <tr>
                                <td className="text-center text-muted" colSpan={9}>
                                    <RichMessage id="gift-cards.table.empty" />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}

            {/* Add gift card modal*/}
            {isAddGiftCardModalOpen && (
                <AddGiftCardModal
                    isOpen={isAddGiftCardModalOpen}
                    onHide={() => setIsAddGiftCardModalOpen(false)}
                    onGiftCardCreated={handleGiftCardCreated}
                ></AddGiftCardModal>
            )}

            {/* Archive confirmation modal */}
            {isArchiveModalOpen && (
                <ConfirmationModal
                    isOpen={isArchiveModalOpen}
                    isCloseDisabled={actionInProgress}
                    icon={faArchive}
                    title={<RichMessage id="gift-cards.archive-modal.header" />}
                    content={
                        <div className="text-center">
                            <RichMessage id="gift-cards.archive-modal.body" />
                        </div>
                    }
                    customConfirmButton={
                        <AsyncButton
                            variant="primary"
                            onClick={handleArchiveGiftCards}
                            disabled={actionInProgress}
                            loading={actionInProgress}
                            messageProps={{ id: 'gift-cards.archive-modal.footer.archive' }}
                            loadingMessageProps={{ id: 'gift-cards.archive-modal.footer.archiving' }}
                        />
                    }
                    onCancel={() => setIsArchiveModalOpen(false)}
                />
            )}

            {/* Deletion confirmation modal */}
            {isDeletionModalOpen && (
                <ConfirmationModal
                    isOpen={isDeletionModalOpen}
                    isCloseDisabled={actionInProgress}
                    icon={faTrash}
                    iconClassname="text-danger"
                    title={<RichMessage id="gift-cards.deletion-modal.header" />}
                    content={
                        <div className="text-center">
                            <RichMessage id="gift-cards.deletion-modal.body" />
                        </div>
                    }
                    customConfirmButton={
                        <AsyncButton
                            variant="primary"
                            onClick={handleDeleteGiftCards}
                            disabled={actionInProgress}
                            loading={actionInProgress}
                            messageProps={{ id: 'gift-cards.deletion-modal.footer.delete' }}
                            loadingMessageProps={{ id: 'gift-cards.deletion-modal.footer.deleting' }}
                        />
                    }
                    onCancel={() => setIsDeletionModalOpen(false)}
                />
            )}
        </>
    );
};

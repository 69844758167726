import { SetStateAction, Dispatch, useState, useRef, useCallback } from 'react';

// Same as React.useState except that the state setter will be a no-op if the component is unmounted.
export function useSafeState<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] {
    // State
    const [state, setState] = useState<S>(initialState);

    // Refs
    const isMounted = useRef(true);

    // Define setter that only acts if the component is mounted
    const setSafeState = useCallback((value: SetStateAction<S>): void => {
        isMounted.current && setState(value);
    }, []);

    return [state, setSafeState];
}

import { FunctionComponent } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import OMN_LOCAUX from 'assets/img/omn-locaux.jpg';

const IMAGES: ReactImageGalleryItem[] = [{ original: OMN_LOCAUX }];

export const Carousel: FunctionComponent = () => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <ImageGallery items={IMAGES} showNav autoPlay />
        </div>
    );
};

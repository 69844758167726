import { FunctionComponent, useMemo } from 'react';
import { RichMessage } from '../RichMessage';
import { useBreakpoint } from '../../util/useBreakpoint';

const PRESTATION_TYPES = [
    'onglerie',
    'microblading',
    'rehaussement-de-cils',
    'soins-du-visage',
    'epilation',
    'arganicare',
] as const;
type PrestationType = (typeof PRESTATION_TYPES)[number];

interface Props {
    type: PrestationType;
}

const PRESTATION_IMAGES: { [key: string]: string[] } = {
    onglerie: ['onglerie-1.png', 'onglerie-2.png', 'onglerie-3.png'],
    microblading: ['microblading-1.png', 'microblading-2.png'],
    'rehaussement-de-cils': ['rehaussement-de-cils-1.png'],
    'soins-du-visage': ['soins-du-visage-1.jpg'],
    epilation: ['epilation-1.png'],
    arganicare: ['arganicare-1.png', 'arganicare-2.jpg', 'arganicare-3.jpg', 'arganicare-4.jpg', 'arganicare-5.jpg'],
};

export const Prestation: FunctionComponent<Props> = ({ type }: Props) => {
    // Services
    const breakpoint = useBreakpoint();

    // Grid template columns, depending on breakpoint
    const gridTemplateColumns = useMemo(() => {
        if (breakpoint === 'xs' || breakpoint === 'sm')
            return `repeat(${Math.min(1, PRESTATION_IMAGES[type].length)}, 1fr)`;
        if (breakpoint === 'md') return `repeat(${Math.min(2, PRESTATION_IMAGES[type].length)}, 1fr)`;
        return `repeat(${Math.min(3, PRESTATION_IMAGES[type].length)}, 1fr)`;
    }, [breakpoint, type]);

    return (
        <>
            {/* Title */}
            <h4 className="fw-bold mb-4">
                <RichMessage id={`prestations.${type}.title`} />
            </h4>

            {/* Body */}
            <p className="mb-5" style={{ textAlign: 'justify' }}>
                <RichMessage id={`prestations.${type}.body`} />
            </p>

            {/* Photos */}
            <div className="d-grid gap-1 align-items-center" style={{ gridTemplateColumns }}>
                {PRESTATION_IMAGES[type].map((e) => (
                    <img
                        key={e}
                        style={{ maxWidth: 600 }}
                        className="m-auto w-100"
                        src={require(`assets/img/prestations/${e}`)}
                        alt={e}
                    />
                ))}
            </div>
        </>
    );
};

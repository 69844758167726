import { FunctionComponent, useCallback, useState } from 'react';
import { GiftCardUse } from '../../../api/models/gift-card';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import useQuery from '../../../api/hooks/useQuery';

interface Props {
    giftCardId: string;
    use: GiftCardUse;
    onDelete: () => void;
}

const GiftCardUseRow: FunctionComponent<Props> = ({ giftCardId, use, onDelete }) => {
    // State
    const [deletionInProgress, setDeletionInProgress] = useState<boolean>(false);

    // Mutations
    const deleteGiftCardUse = useQuery('delete', '/gift-cards/:id/uses/:id');

    // Hook invoked when a use is deleted
    const handleDeleteUse = useCallback(async () => {
        // Abort if deletion is already in progress
        if (deletionInProgress || !use.id) return;

        // Set loading state
        setDeletionInProgress(true);

        // Request deletion
        const res = await deleteGiftCardUse({ resourceIds: [giftCardId, use.id] });

        // Reset loading state
        setDeletionInProgress(false);

        // If response was successful
        if (res) onDelete();
    }, [deletionInProgress, use.id, deleteGiftCardUse, giftCardId, onDelete]);

    return (
        <tr>
            <td>{moment(use.date).format('DD/MM/YYYY')}</td>
            <td>{use.amount.toFixed(2)}€</td>
            <td className="text-center">
                <Button className="p-0" variant="link" disabled={deletionInProgress} onClick={handleDeleteUse}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </td>
        </tr>
    );
};

export default GiftCardUseRow;

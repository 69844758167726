import { AxiosRequestConfig } from 'axios';
import { useCallback, useContext } from 'react';
import { AxiosInstance } from '../AxiosInstance';
import { useHandleError } from './useHandleError';
import { AuthContext } from '../../auth/AuthContext';

const useQuery = <TRequest, TResponse>(
    method: 'get' | 'patch' | 'post' | 'put' | 'delete',
    url: string,
    params?: AxiosRequestConfig
) => {
    // Context
    const { token } = useContext(AuthContext);

    // Handle error
    const handleError = useHandleError();

    return useCallback(
        async (opts?: { body?: TRequest; resourceIds?: string[] }) => {
            // Replace `:id` by `resourceIds` in URL, if provided
            const formattedUrl = opts?.resourceIds
                ? opts.resourceIds.reduce((formattedUrl, resourceId) => formattedUrl.replace(':id', resourceId), url)
                : url;

            try {
                switch (method) {
                    case 'get':
                    case 'delete':
                        return await AxiosInstance[method]<TResponse>(formattedUrl, {
                            ...params,
                            ...(token
                                ? {
                                      headers: { Authorization: `Bearer ${token}` },
                                  }
                                : {}),
                        });
                    case 'post':
                    case 'put':
                    case 'patch':
                        return await AxiosInstance[method]<TResponse>(formattedUrl, opts?.body, {
                            ...params,
                            ...(token
                                ? {
                                      headers: { Authorization: `Bearer ${token}` },
                                  }
                                : {}),
                        });
                }
            } catch (error: any) {
                // Set error
                console.error(error);
                handleError(error);
            }
        },
        [handleError, method, params, token, url]
    );
};

export default useQuery;

import { FunctionComponent } from 'react';
import { RichMessage } from '../../../components/RichMessage';

export const LegalNotice: FunctionComponent = () => {
    return (
        <>
            <h4 className="fw-bold mb-4">
                <RichMessage id="legal-notice.title" />
            </h4>

            <RichMessage id="legal-notice.description" />
        </>
    );
};

import { FunctionComponent } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RichMessage } from '../RichMessage';
import classNames from 'classnames';

interface Props {
    className?: string;
}

export const Footer: FunctionComponent<Props> = ({ className }) => {
    return (
        <footer className={classNames('omn-footer', className)}>
            <Container className="fs-sm px-3 py-2 d-grid d-sm-flex justify-content-between">
                <Nav.Link as={Link} to="/contact">
                    <RichMessage id="footer.contact" />
                </Nav.Link>
                <Nav.Link as={Link} to="/mentions-legales">
                    <RichMessage id="footer.legal-notice" />
                </Nav.Link>
                <Nav.Link as={Link} to="/cgv">
                    <RichMessage id="footer.terms" />
                </Nav.Link>
            </Container>
        </footer>
    );
};

import React, { Context, createContext, FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthenticateRequestBody, AuthenticateResponse } from '../api/models/authenticate';
import useQuery from '../api/hooks/useQuery';

interface AuthContextProps {
    token: string;
    username: string | null;
    loginLoading: boolean;
    login: (body: AuthenticateRequestBody) => Promise<void>;
    logout: () => void;
}

export const AuthContext: Context<AuthContextProps> = createContext({
    token: '',
    username: null,
    loginLoading: false,
    login: async (body: AuthenticateRequestBody) => undefined,
    logout: () => undefined,
} as AuthContextProps);

interface AuthContextProviderProps {
    children: ReactNode[] | ReactNode;
}

export const AuthContextProvider: FunctionComponent<AuthContextProviderProps> = ({ children }) => {
    // State
    const [loginLoading, setLoginLoading] = useState<boolean>(false);
    const [username, setUsername] = useState<string | null>(null);
    const [token, setToken] = useState(localStorage.getItem('token') || '');

    // Services
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Mutation
    const authenticate = useQuery<AuthenticateRequestBody, AuthenticateResponse>('post', '/auth/sign-in');

    // Hook invoked to login
    const login = useCallback(
        async (body: AuthenticateRequestBody) => {
            // Set loading state
            setLoginLoading(true);

            // Request login to server
            const res = await authenticate({ body });

            if (res?.data) {
                // Update user state
                setUsername(res.data.username);

                // Update token state
                setToken(res.data.token);

                // Store token in local storage
                localStorage.setItem('token', res.data.token);

                // Redirect
                const redirectTo = searchParams.get('redirectUrl');
                if (redirectTo) {
                    navigate(redirectTo);
                } else {
                    navigate('/admin/cheques-cadeaux');
                }
            }

            // Reset loading state
            setLoginLoading(false);
        },
        [authenticate, navigate, searchParams]
    );

    // Hook invoked to logout
    const logout = useCallback(() => {
        // Reset username
        setUsername(null);

        // Reset token
        setToken('');

        // Cleara local storage
        localStorage.removeItem('token');

        // Redirect to login
        navigate('/admin/login');
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ token, username, loginLoading, login, logout }}>{children}</AuthContext.Provider>
    );
};

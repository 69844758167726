import { FunctionComponent } from 'react';
import { RichMessage } from '../../../components/RichMessage';

import OMN_LOGO from 'assets/img/omn-wpp.png';
import OMN_LOCAUX from 'assets/img/omn-locaux.jpg';

export const Home: FunctionComponent = () => {
    return (
        <div className="text-center">
            <img className="mb-4 mw-100" src={OMN_LOGO} alt="logo" />
            <div className="mb-4">
                <RichMessage id="home.text" />
            </div>
            <img className="mw-100" src={OMN_LOCAUX} alt="locaux" />
        </div>
    );
};

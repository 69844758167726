import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import { RichMessage } from '../RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ProductsDropdown } from '../ProductsDropdown';
import useQuery from '../../api/hooks/useQuery';
import { GiftCard } from '../../api/models/gift-card';
import { AxiosResponse } from 'axios';
import useInitializationQuery from '../../api/hooks/useInitializationQuery';
import { Product } from '../../api/models/product';
import moment from 'moment';

interface Props {
    isOpen: boolean;
    onHide: () => void;
    onGiftCardCreated: () => void;
}

const AddGiftCardModal: FunctionComponent<Props> = ({ isOpen, onHide, onGiftCardCreated }) => {
    // State
    const [type, setType] = useState<'amount' | 'products'>('amount');
    const [product, setProduct] = useState<string | null>('');
    const [amount, setAmount] = useState<number>(50);
    const [sender, setSender] = useState<string>('');
    const [recipient, setRecipient] = useState<string>('');
    const [expirationDate, setExpirationDate] = useState<string>(moment().add(6, 'months').format('YYYY-MM-DD'));
    const [creationInProgress, setCreationInProgress] = useState<boolean>(false);

    // Form validation
    const isFormValid = useMemo(
        () =>
            !!type &&
            ((type === 'amount' && !!amount) || (type === 'products' && !!product)) &&
            !!sender &&
            !!recipient &&
            !!expirationDate,
        [amount, expirationDate, product, recipient, sender, type]
    );

    // Fetch products
    const { data: products, loading: loadingProducts } = useInitializationQuery<Product[]>('get', '/products');

    // Create gift card
    const createGiftCard = useQuery<GiftCard, AxiosResponse>('post', '/gift-cards');

    // Hook invoked when selecting a type
    const handleSelect = useCallback((e: string | null) => {
        // Abort if null
        if (!e) return;

        // Update state
        setType(e as 'amount' | 'products');
    }, []);

    // Hook invoked when add button is clicked
    const handleSubmit = useCallback(async () => {
        // Abort if form is not valid or products are loading or gift card is already creating
        if (!isFormValid || loadingProducts || creationInProgress) return;

        // Set loading state
        setCreationInProgress(true);

        // Build amount and name depending on gift card type
        let restProps;

        if (type === 'amount') {
            restProps = {
                amount,
                name: 'Montant',
            };
        } else if (type === 'products') {
            // Find product by `priceId`
            const selectedProduct = products?.find((e) => e.priceId === product);

            // Abort if not found
            if (!selectedProduct) return;

            restProps = {
                amount: selectedProduct.amount,
                name: selectedProduct.product.name,
            };
        }

        // Abort if no details
        if (!restProps) return;

        // Request creation
        const res = await createGiftCard({
            body: {
                type,
                sender,
                recipient,
                expirationDate: new Date(expirationDate),
                ...restProps,
            },
        });

        // Reset loading state
        setCreationInProgress(false);

        // If response was successful
        if (res) {
            onGiftCardCreated();
        }
    }, [
        amount,
        createGiftCard,
        creationInProgress,
        expirationDate,
        isFormValid,
        loadingProducts,
        onGiftCardCreated,
        product,
        products,
        recipient,
        sender,
        type,
    ]);

    return (
        <Modal backdrop="static" show={isOpen} onHide={onHide}>
            {/* Header */}
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon className="me-2" icon={faPlus} />
                    <RichMessage id="add-gift-card-modal.header" />
                </Modal.Title>
            </Modal.Header>

            {/* Body */}
            <Modal.Body className="px-4">
                {loadingProducts ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="dark" />
                    </div>
                ) : (
                    <Form>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                <RichMessage id="add-gift-card-modal.body.form.type.label" />
                            </Form.Label>
                            <Dropdown onSelect={handleSelect}>
                                <Dropdown.Toggle disabled={creationInProgress} className="w-100" variant="outline-dark">
                                    {type ? (
                                        <RichMessage id={`add-gift-card-modal.body.form.type.${type}`} />
                                    ) : (
                                        <RichMessage id="add-gift-card-modal.body.form.type.placeholder" />
                                    )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="amount">
                                        <RichMessage id="add-gift-card-modal.body.form.type.amount" />
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="products">
                                        <RichMessage id="add-gift-card-modal.body.form.type.products" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            {type === 'products' ? (
                                <>
                                    <Form.Label>
                                        <RichMessage id="add-gift-card-modal.body.form.products.label" />
                                    </Form.Label>
                                    <ProductsDropdown
                                        disabled={creationInProgress}
                                        selected={product}
                                        onSelect={(e) => setProduct(e)}
                                    />
                                </>
                            ) : type === 'amount' ? (
                                <>
                                    <Form.Label>
                                        <RichMessage id="add-gift-card-modal.body.form.amount.label" />
                                    </Form.Label>
                                    <Form.Control
                                        disabled={creationInProgress}
                                        type="number"
                                        value={amount}
                                        onChange={(e) => setAmount(+e.target.value)}
                                    />
                                </>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                <RichMessage id="add-gift-card-modal.body.form.sender.label" />
                            </Form.Label>
                            <Form.Control
                                disabled={creationInProgress}
                                type="text"
                                value={sender}
                                onChange={(e) => setSender(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                <RichMessage id="add-gift-card-modal.body.form.recipient.label" />
                            </Form.Label>
                            <Form.Control
                                disabled={creationInProgress}
                                type="text"
                                value={recipient}
                                onChange={(e) => setRecipient(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                <RichMessage id="add-gift-card-modal.body.form.expiration-date.label" />
                            </Form.Label>
                            <Form.Control
                                disabled={creationInProgress}
                                type="date"
                                value={expirationDate}
                                onChange={(e) => setExpirationDate(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                )}
            </Modal.Body>

            {/* Footer */}
            <Modal.Footer>
                <Button variant="outline-dark" onClick={onHide}>
                    <RichMessage id="add-gift-card-modal.footer.cancel" />
                </Button>
                <Button disabled={!isFormValid || loadingProducts || creationInProgress} onClick={handleSubmit}>
                    {creationInProgress ? (
                        <RichMessage id="add-gift-card-modal.footer.adding" />
                    ) : (
                        <RichMessage id="add-gift-card-modal.footer.add" />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddGiftCardModal;

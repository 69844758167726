import axios from 'axios';

// Server URL
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// Create Axios instance to use app-wide
export const AxiosInstance = axios.create({
    baseURL: SERVER_URL,
    timeout: 5000,
});

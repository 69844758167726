import { FunctionComponent } from 'react';
import { RichMessage } from '../../../components/RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useIntl } from 'react-intl';

export const Contact: FunctionComponent = () => {
    // Services
    const intl = useIntl();

    return (
        <div className="m-auto" style={{ maxWidth: 600 }}>
            <h4 className="fw-bold mb-4">
                <RichMessage id="contact.title" />
            </h4>

            <div className="mb-4">
                <RichMessage id="contact.description" />
            </div>

            <div>
                <div>
                    <a
                        className="d-flex align-items-center"
                        href={`tel:${intl.formatMessage({ id: 'contact.phone.href' })}`}
                    >
                        <FontAwesomeIcon className="me-2" icon={faPhone} />
                        <span>
                            <RichMessage id="contact.phone" />
                        </span>
                    </a>
                </div>
                <div>
                    <a
                        className="d-flex align-items-center"
                        href={`mailto:${intl.formatMessage({ id: 'contact.email.href' })}`}
                    >
                        <FontAwesomeIcon className="me-2" icon={faEnvelope} />
                        <span>
                            <RichMessage id="contact.email" />
                        </span>
                    </a>
                </div>
                <br />
                <div>
                    <a
                        className="d-flex align-items-center"
                        href={intl.formatMessage({ id: 'contact.social.facebook.href' })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon className="me-2" icon={faFacebook} />
                        <span>
                            <RichMessage id="contact.social.facebook" />
                        </span>
                    </a>
                </div>
                <div>
                    <a
                        className="d-flex align-items-center"
                        href={intl.formatMessage({ id: 'contact.social.insta.href' })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon className="me-2" icon={faInstagram} />
                        <span>
                            <RichMessage id="contact.social.insta" />
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
};

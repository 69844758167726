import { FunctionComponent } from 'react';
import { RichMessage } from '../../../components/RichMessage';

import GIFT_CARD from 'assets/img/gift-card.jpg';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export const GiftCard: FunctionComponent = () => {
    return (
        <div className="m-auto" style={{ maxWidth: 600 }}>
            <h4 className="fw-bold mb-4">
                <RichMessage id="gift-card.title" />
            </h4>

            <div className="mb-4">
                <RichMessage id="gift-card.description" />
            </div>

            <div className="text-center mb-4">
                <img className="mw-100" src={GIFT_CARD} alt="gift-card" />
            </div>

            <div className="text-center">
                <Button as={Link as any} to="/cheque-cadeau/form" className="px-4 py-3 fw-bold fs-5" variant="primary">
                    <FontAwesomeIcon className="me-3" icon={faGift} />
                    <RichMessage id="gift-card.button" />
                </Button>
            </div>
        </div>
    );
};

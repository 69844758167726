import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { RichMessage } from '../RichMessage';
import useQuery from '../../api/hooks/useQuery';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { GiftCard, GiftCardUse } from '../../api/models/gift-card';

interface Props {
    giftCard: GiftCard;
    isOpen: boolean;
    onHide: () => void;
    onUseAdded: () => void;
}

const AddGiftCardUseModal: FunctionComponent<Props> = ({ giftCard, isOpen, onHide, onUseAdded }) => {
    // State
    const [amount, setAmount] = useState<number>(10);
    const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));
    const [creationInProgress, setCreationInProgress] = useState<boolean>(false);

    // Form validation
    const isFormValid = useMemo(() => !!amount && !!date, [amount, date]);

    // Add gift card use
    const createGiftCardUse = useQuery<GiftCardUse, AxiosResponse>('post', '/gift-cards/:id/uses');

    // Hook invoked when add button is clicked
    const handleSubmit = useCallback(async () => {
        // Abort if form is not valid or gift card id is missing or gift card is already creating
        if (!isFormValid || !giftCard.id || creationInProgress) return;

        // Set loading state
        setCreationInProgress(true);

        // Request creation
        const res = await createGiftCardUse({
            resourceIds: [giftCard.id],
            body: {
                amount,
                date: new Date(date),
            },
        });

        // Reset loading state
        setCreationInProgress(false);

        // If response was successful
        if (res) {
            onUseAdded();
        }
    }, [isFormValid, giftCard.id, creationInProgress, createGiftCardUse, amount, date, onUseAdded]);

    return (
        <Modal backdrop="static" show={isOpen} onHide={onHide}>
            {/* Header */}
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon className="me-2" icon={faPlus} />
                    <RichMessage id="gift-card-details.add-use-modal.header" />
                </Modal.Title>
            </Modal.Header>

            {/* Body */}
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-2">
                        <Form.Label>
                            <RichMessage id="gift-card-details.add-use-modal.body.form.date.label" />
                        </Form.Label>
                        <Form.Control
                            disabled={creationInProgress}
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>
                            <RichMessage id="gift-card-details.add-use-modal.body.form.amount.label" />
                        </Form.Label>
                        <Form.Control
                            disabled={creationInProgress}
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(+e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>

            {/* Footer */}
            <Modal.Footer>
                <Button variant="outline-dark" onClick={onHide}>
                    <RichMessage id="gift-card-details.add-use-modal.footer.cancel" />
                </Button>
                <Button disabled={creationInProgress} onClick={handleSubmit}>
                    {creationInProgress ? (
                        <RichMessage id="gift-card-details.add-use-modal.footer.adding" />
                    ) : (
                        <RichMessage id="gift-card-details.add-use-modal.footer.add" />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddGiftCardUseModal;

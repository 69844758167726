import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { RichMessage } from '../../../components/RichMessage';
import { Button, Form } from 'react-bootstrap';
import { AuthContext } from '../../../auth/AuthContext';

export const Login: FunctionComponent = () => {
    // State
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { loginLoading, login } = useContext(AuthContext);

    // Hook invoked when form is submitted
    const handleSubmit = useCallback(async () => {
        await login({ username, password });
    }, [login, password, username]);

    return (
        <div className="m-auto" style={{ maxWidth: 600 }}>
            {/* Title */}
            <h4 className="fw-bold mb-4">
                <RichMessage id="login.title" />
            </h4>

            {/* Form */}
            <Form>
                {/* Username */}
                <Form.Group className="mb-3">
                    <Form.Label>
                        <RichMessage id="login.form.username.label" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        autoComplete="nickname"
                        disabled={loginLoading}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>

                {/* Password */}
                <Form.Group className="mb-3">
                    <Form.Label>
                        <RichMessage id="login.form.password.label" />
                    </Form.Label>
                    <Form.Control
                        type="password"
                        autoComplete="current-password"
                        disabled={loginLoading}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                {/* Submit button */}
                <div className="text-center">
                    <Button
                        className="fw-bold"
                        type="submit"
                        disabled={!username || !password || loginLoading}
                        onClick={handleSubmit}
                    >
                        {loginLoading ? (
                            <RichMessage id="login.form.submitting" />
                        ) : (
                            <RichMessage id="login.form.submit" />
                        )}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useHandleError = () => {
    // Services
    const navigate = useNavigate();
    const location = useLocation();

    return useCallback(
        (error: any) => {
            console.error(error);

            // Redirect if 401
            if (error.response?.status === 401) {
                // Build search params with `redirectUrl`
                const queryAttrs = new URLSearchParams();
                queryAttrs.set('redirectUrl', location.pathname);

                // Redirect to login
                navigate({
                    pathname: '/admin/login',
                    search: queryAttrs.toString(),
                });
            }
        },
        [location.pathname, navigate]
    );
};

import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { RichMessage } from '../RichMessage';
import useInitializationQuery from '../../api/hooks/useInitializationQuery';
import { Product } from '../../api/models/product';

interface Props {
    className?: string;
    disabled?: boolean;
    selected: string | null;
    onSelect: (e: string | null) => void;
}

export const ProductsDropdown: FunctionComponent<Props> = ({ className, disabled, selected, onSelect }) => {
    // State
    const [localSelected, setLocalSelected] = useState<string | null>('');

    // Fetch products
    const { data: products, loading } = useInitializationQuery<Product[]>('get', '/products');

    // Hook invoked when a dropdown item is selected
    const handleSelect = useCallback(
        (e: string | null) => {
            // Update local state
            setLocalSelected(e);

            // Propagate to parent
            onSelect(e);
        },
        [onSelect]
    );

    // Re-sync
    useEffect(() => {
        if (localSelected !== selected) setLocalSelected(selected);
    }, [localSelected, products, selected]);

    return (
        <Dropdown className={className} onSelect={handleSelect}>
            <Dropdown.Toggle disabled={disabled || loading} className="w-100" variant="outline-dark">
                {localSelected ? (
                    products?.find((p) => localSelected === p.priceId)?.product.name
                ) : (
                    <RichMessage id="components.products-dropdown.placeholder" />
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
                {products?.map((e) => (
                    <Dropdown.Item key={e.priceId} eventKey={e.priceId}>
                        {e.amount}€ - {e.product.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

import { FunctionComponent } from 'react';
import {
    Navigate,
    Route,
    RouterProvider,
    Routes,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import AppLocale from '../lang';
import { AuthContextProvider } from '../auth/AuthContext';
import AuthGuard from '../auth/AuthGuard';
import PublicLayout from './public/Layout';
import { Home } from './public/Home';
import { Prestation } from '../components/Prestation';
import { Tarifs } from '../components/Tarifs';
import { Carousel } from './public/Carousel';
import { Contact } from './public/Contact';
import { GiftCard } from './public/GiftCard';
import GiftCardForm from './public/GiftCard/GiftCardForm';
import GiftCardPostRedirect from './public/GiftCard/GiftCardPostRedirect';
import { Terms } from './public/Terms/Terms';
import { LegalNotice } from './public/LegalNotice/LegalNotice';
import AdminLayout from './admin/Layout';
import { Login } from './admin/Login';
import { GiftCards } from './admin/GiftCards';
import GiftCardDetails from './admin/GiftCards/GiftCardDetails';

const Root: FunctionComponent = () => {
    // Get actual locale
    const locale = 'fr';
    const currentAppLocale = AppLocale[locale];

    return (
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <AuthContextProvider>
                <Routes>
                    {/* Customer routes */}
                    <Route element={<PublicLayout />}>
                        <Route index element={<Navigate to="home" />} />
                        <Route path="home" element={<Home />} />
                        <Route path="prestations">
                            <Route index element={<Navigate to="onglerie" />} />
                            <Route path="onglerie" element={<Prestation type="onglerie" />} />
                            <Route path="microblading" element={<Prestation type="microblading" />} />
                            <Route path="rehaussement-de-cils" element={<Prestation type="rehaussement-de-cils" />} />
                            <Route path="soins-du-visage" element={<Prestation type="soins-du-visage" />} />
                            <Route path="epilation" element={<Prestation type="epilation" />} />
                            <Route path="arganicare" element={<Prestation type="arganicare" />} />
                        </Route>
                        <Route path="tarifs">
                            <Route index element={<Navigate to="onglerie" />} />
                            <Route path="onglerie" element={<Tarifs type="onglerie" title="Onglerie" />} />
                            <Route
                                path="soins-du-visage"
                                element={<Tarifs type="soins-du-visage" title="Soins du visage" />}
                            />
                            <Route path="epilation" element={<Tarifs type="epilation" title="Epilation" />} />
                            <Route
                                path="beaute-du-regard"
                                element={<Tarifs type="beaute-du-regard" title="Beauté du regard" />}
                            />
                            <Route path="microblading" element={<Tarifs type="microblading" title="Microblading" />} />
                            <Route path="maquillage" element={<Tarifs type="maquillage" title="Maquillage" />} />
                        </Route>
                        <Route path="galerie-photos" element={<Carousel />} />
                        <Route path="cheque-cadeau">
                            <Route index element={<GiftCard />} />
                            <Route path="form">
                                <Route index element={<GiftCardForm />} />
                                <Route path="success" element={<GiftCardPostRedirect status="success" />} />
                                <Route path="canceled" element={<GiftCardPostRedirect status="canceled" />} />
                            </Route>
                        </Route>
                        <Route path="contact" element={<Contact />} />
                        <Route path="cgv" element={<Terms />} />
                        <Route path="mentions-legales" element={<LegalNotice />} />
                    </Route>

                    {/* Admin routes */}
                    <Route path="admin">
                        <Route element={<AdminLayout />}>
                            <Route index element={<Navigate to="cheques-cadeaux" />} />
                            <Route path="login" element={<Login />} />
                            <Route element={<AuthGuard />}>
                                <Route path="cheques-cadeaux">
                                    <Route index element={<GiftCards />} />
                                    <Route path=":giftCardId" element={<GiftCardDetails />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </AuthContextProvider>
        </IntlProvider>
    );
};

// Router singleton
const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<Root />} />));

export const AppRoutes: FunctionComponent = () => {
    return <RouterProvider router={router} />;
};

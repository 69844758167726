import { FunctionComponent } from 'react';
import { RichMessage } from '../../../components/RichMessage';

export const Terms: FunctionComponent = () => {
    return (
        <>
            <h4 className="fw-bold mb-4">
                <RichMessage id="terms.title" />
            </h4>

            <RichMessage id="terms.description" />
        </>
    );
};

import { FunctionComponent, useEffect, useState } from 'react';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import OMN_LOGO from 'assets/img/omn-icon.png';
import classNames from 'classnames';
import { RichMessage } from '../RichMessage';
import { useIntl } from 'react-intl';

interface Props {
    className?: string;
}

const PublicNavbar: FunctionComponent<Props> = ({ className }: Props) => {
    // State
    const [expanded, setExpanded] = useState(false);

    // Services
    const location = useLocation();
    const intl = useIntl();

    // Collapse navbar on route change
    useEffect(() => setExpanded(false), [location]);

    return (
        <Navbar expand="lg" expanded={expanded} className={classNames('omn-navbar', className)}>
            <Container>
                <Navbar.Brand className="d-flex align-items-center" as={Link} to="home">
                    <img className="me-2" src={OMN_LOGO} alt="oh-my-nails" />
                    <RichMessage id="public-navbar.home" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="omn-navbar" onClick={() => setExpanded((e) => !e)} />
                <Navbar.Collapse id="omn-navbar">
                    <Nav className="me-auto fw-bold">
                        <NavDropdown title={intl.formatMessage({ id: 'public-navbar.prestations.title' })}>
                            <NavDropdown.Item as={Link} to="/prestations/onglerie">
                                <RichMessage id="public-navbar.prestations.onglerie" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/prestations/microblading">
                                <RichMessage id="public-navbar.prestations.microblading" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/prestations/rehaussement-de-cils">
                                <RichMessage id="public-navbar.prestations.rehaussement-de-cils" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/prestations/soins-du-visage">
                                <RichMessage id="public-navbar.prestations.soins-du-visage" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/prestations/epilation">
                                <RichMessage id="public-navbar.prestations.epilation" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/prestations/arganicare">
                                <RichMessage id="public-navbar.prestations.arganicare" />
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={intl.formatMessage({ id: 'public-navbar.tarifs.title' })}>
                            <NavDropdown.Item as={Link} to="/tarifs/onglerie">
                                <RichMessage id="public-navbar.tarifs.onglerie" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/tarifs/soins-du-visage">
                                <RichMessage id="public-navbar.tarifs.soins-du-visage" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/tarifs/epilation">
                                <RichMessage id="public-navbar.tarifs.epilation" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/tarifs/beaute-du-regard">
                                <RichMessage id="public-navbar.tarifs.beaute-du-regard" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/tarifs/microblading">
                                <RichMessage id="public-navbar.tarifs.microblading" />
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/tarifs/maquillage">
                                <RichMessage id="public-navbar.tarifs.maquillage" />
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/galerie-photos">
                            <RichMessage id="public-navbar.tarifs.galerie-photos" />
                        </Nav.Link>
                        <Nav.Link as={Link} to="/cheque-cadeau">
                            <RichMessage id="public-navbar.tarifs.cheque-cadeau" />
                        </Nav.Link>
                        <Nav.Link as={Link} to="/contact">
                            <RichMessage id="public-navbar.tarifs.contact" />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default PublicNavbar;

import { ChangeEvent, FunctionComponent, useCallback, useMemo } from 'react';
import { GiftCard, GiftCardUse } from '../../../api/models/gift-card';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Form, ProgressBar, Spinner } from 'react-bootstrap';
import { useGiftCardUsage } from './useGiftCardUsage';
import useInitializationQuery from '../../../api/hooks/useInitializationQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useIntl } from 'react-intl';

interface Props {
    giftCard: GiftCard;
    selected: boolean;
    onSelectChange: (selected: boolean) => void;
    onDelete: () => void;
}

const GiftCardRow: FunctionComponent<Props> = ({ giftCard, selected, onSelectChange, onDelete }) => {
    // Fetch gift card uses
    const { data: uses, loading: loadingUses } = useInitializationQuery<GiftCardUse[]>(
        'get',
        `/gift-cards/${giftCard.id}/uses`
    );

    // Services
    const navigate = useNavigate();
    const intl = useIntl();
    const { usedPercentage } = useGiftCardUsage(giftCard, uses);

    // Description
    const description = useMemo(
        () =>
            giftCard.type === 'amount'
                ? intl.formatMessage({ id: 'gift-cards.table.td.description.amount' })
                : giftCard.type === 'products'
                ? giftCard.name
                : '',
        [giftCard.name, giftCard.type, intl]
    );

    // Hook invoked when the details button is clicked
    const handleNavigateToGiftCard = useCallback(
        (id?: string) => {
            // Abort if no id
            if (!id) return;

            // Navigate to gift card details
            navigate(`/admin/cheques-cadeaux/${id}`);
        },
        [navigate]
    );

    // Hook invoked when a row is selected/unselected
    const handleSelectChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            // Perform the event
            onSelectChange(e.target.checked);
        },
        [onSelectChange]
    );

    return (
        <>
            <tr className="align-middle" role="button" onClick={() => onSelectChange(!selected)}>
                <td className="text-center">
                    <Form.Check
                        type="checkbox"
                        checked={selected}
                        onChange={handleSelectChange}
                        onClick={(e) => e.stopPropagation()}
                    />
                </td>
                <td className="text-nowrap">{giftCard.reference}</td>
                <td className="text-truncate" style={{ maxWidth: 190 }} title={description}>
                    {description}
                </td>
                <td className="text-truncate" style={{ maxWidth: 120 }} title={giftCard.sender}>
                    {giftCard.sender}
                </td>
                <td className="text-truncate" style={{ maxWidth: 120 }} title={giftCard.recipient}>
                    {giftCard.recipient}
                </td>
                <td className="text-center">{moment(giftCard.expirationDate).format('DD/MM/YYYY')}</td>
                <td className="text-center">{giftCard.amount.toFixed(2)}€</td>
                <td className="text-center">
                    {loadingUses ? (
                        <Spinner animation="border" size="sm" variant="dark" />
                    ) : (
                        <ProgressBar now={usedPercentage} />
                    )}
                </td>
                <td className="text-center">
                    <Button variant="link" className="p-0" onClick={() => handleNavigateToGiftCard(giftCard.id)}>
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </td>
            </tr>
        </>
    );
};

export default GiftCardRow;

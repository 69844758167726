import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import OMN_LOGO from 'assets/img/omn-icon.png';
import { AuthContext } from '../../auth/AuthContext';
import { RichMessage } from '../RichMessage';

interface Props {
    className?: string;
}

const AdminNavbar: FunctionComponent<Props> = ({ className }: Props) => {
    // State
    const [expanded, setExpanded] = useState(false);

    // Context
    const { token, logout } = useContext(AuthContext);

    // Services
    const location = useLocation();

    // Collapse navbar on route change
    useEffect(() => setExpanded(false), [location]);

    return (
        <Navbar expand="lg" expanded={expanded} className={classNames('omn-navbar', className)}>
            <Container>
                <Navbar.Brand className="d-flex align-items-center" as={Link} to="/home">
                    <img className="me-2" src={OMN_LOGO} alt="oh-my-nails" />
                    <RichMessage id="public-navbar.home" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="omn-navbar" onClick={() => setExpanded((e) => !e)} />
                <Navbar.Collapse id="omn-navbar" className="justify-content-end">
                    {token ? (
                        <>
                            <Nav className="fw-bold me-auto my-2 my-lg-0">
                                <Nav.Link as={Link} to="/admin/cheques-cadeaux">
                                    <RichMessage id="admin-navbar.gift-cards" />
                                </Nav.Link>
                            </Nav>
                            <Nav className="fw-bold">
                                <Nav.Link onClick={() => logout()}>
                                    <RichMessage id="admin-navbar.logout" />
                                </Nav.Link>
                            </Nav>
                        </>
                    ) : (
                        <Nav className="fw-bold">
                            <Nav.Link as={Link} to="/admin/login">
                                <RichMessage id="admin-navbar.login" />
                            </Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default AdminNavbar;

import { FunctionComponent } from 'react';
import { RichMessage } from '../../../components/RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';

interface Props {
    status: 'success' | 'canceled';
}

const GiftCardPostRedirect: FunctionComponent<Props> = ({ status }) => {
    return (
        <div className="m-auto" style={{ maxWidth: 600 }}>
            {/* Title */}
            <h4 className="fw-bold mb-4">
                <RichMessage id={`gift-card.form.${status}.title`} />
            </h4>

            <div className="text-center">
                {status === 'success' && <FontAwesomeIcon icon={faCircleCheck} className="text-success" size="5x" />}
                {status === 'canceled' && <FontAwesomeIcon icon={faCircleXmark} className="text-danger" size="5x" />}
                <h5 className="mt-3 mb-3">
                    <RichMessage id={`gift-card.form.${status}.description`} />
                </h5>
                <p>
                    <RichMessage id={`gift-card.form.${status}.description2`} />
                </p>
            </div>
        </div>
    );
};

export default GiftCardPostRedirect;

import frLang from './entries/fr-FR';

interface LocaleConfig {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const AppLocale: LocaleConfig = {
    fr: frLang,
};

export default AppLocale;

import { useMemo } from 'react';
import { GiftCard, GiftCardUse } from '../../../api/models/gift-card';

export const useGiftCardUsage = (giftCard: GiftCard | undefined, uses?: GiftCardUse[]) => {
    const usesSum = useMemo(
        () => uses?.reduce((sum: number, use: { amount: number }) => (sum += use.amount), 0) || 0,
        [uses]
    );

    return {
        remaining: giftCard ? giftCard.amount - usesSum : 0,
        used: usesSum,
        usedPercentage: giftCard ? (usesSum / giftCard.amount) * 100 : 0,
    };
};

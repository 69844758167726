import React, { FunctionComponent, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const AuthGuard: FunctionComponent = () => {
    // Context
    const { token } = useContext(AuthContext);

    // Services
    const location = useLocation();

    if (!token) {
        // Redirect to login
        const queryAttrs = new URLSearchParams();
        queryAttrs.set('redirectUrl', location.pathname);

        return (
            <Navigate
                to={{
                    pathname: '/admin/login',
                    search: queryAttrs.toString(),
                }}
            />
        );
    }
    return <Outlet />;
};

export default AuthGuard;

import { FunctionComponent, useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GiftCard, GiftCardUse } from '../../../api/models/gift-card';
import useInitializationQuery from '../../../api/hooks/useInitializationQuery';
import { Button, Card, Col, ProgressBar, Row, Spinner, Table } from 'react-bootstrap';
import { RichMessage } from '../../../components/RichMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBarsProgress,
    faChevronLeft,
    faClockRotateLeft,
    faDownload,
    faGift,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import useQuery from '../../../api/hooks/useQuery';
import { useGiftCardUsage } from './useGiftCardUsage';
import AddGiftCardUseModal from '../../../components/AddGiftCardUseModal/AddGiftCardUseModal';
import GiftCardUseRow from './GiftCardUseRow';

const GiftCardDetails: FunctionComponent = () => {
    // State
    const [isAddGiftCardUseModalOpen, setIsAddGiftCardUseModalOpen] = useState<boolean>(false);
    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

    // Services
    const params = useParams() as { giftCardId: string };
    const giftCardId = params.giftCardId;

    // Fetch gift card
    const { data: giftCard, loading: loadingGiftCard } = useInitializationQuery<GiftCard>(
        'get',
        `/gift-cards/${giftCardId}`
    );

    // Fetch gift card uses
    const {
        data: uses,
        loading: loadingUses,
        refetch: refetchUses,
    } = useInitializationQuery<GiftCardUse[]>('get', `/gift-cards/${giftCardId}/uses`);

    // Gift card usage
    const { used, remaining, usedPercentage } = useGiftCardUsage(giftCard, uses);

    // Mutations
    const downloadGiftCard = useQuery('get', '/gift-cards/:id/download', { responseType: 'text' });

    // Hook invoked when download is clicked
    const handleDownload = useCallback(async () => {
        // Abort if no gift card or download is already in progress
        if (!giftCard || downloadInProgress) return;

        // Set loading state
        setDownloadInProgress(true);

        // Download gift card
        const res = await downloadGiftCard({ resourceIds: [giftCardId] });

        if (res?.data) {
            const source = `data:application/pdf;base64,${res?.data}`;
            const link = document.createElement('a');
            link.href = source;
            link.download = `ohmynails_${giftCard.reference}.pdf`;
            link.click();
        }

        // Set loading state
        setDownloadInProgress(false);
    }, [downloadGiftCard, downloadInProgress, giftCard, giftCardId]);

    if (loadingGiftCard || !giftCard)
        return (
            <div className="text-center">
                <Spinner animation="border" variant="dark" />
            </div>
        );

    return (
        <>
            {/* Back button */}
            <Button
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                as={Link as any}
                to={'/admin/cheques-cadeaux'}
                variant="link"
                className="p-0 pt-1 mb-3"
            >
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" size="sm" />
                <RichMessage id="gift-card-details.back" />
            </Button>

            <Row>
                <Col xs="12" md="8" className="mb-3">
                    {/* Gift card > Details */}
                    <Card className="h-100">
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon className="me-2" icon={faGift} />
                                <span className="fw-bold fs-5">
                                    <RichMessage id="gift-card-details.details-card.title" />
                                </span>
                            </div>
                            <Button
                                variant="link"
                                className="p-0"
                                disabled={downloadInProgress}
                                onClick={handleDownload}
                            >
                                <FontAwesomeIcon icon={faDownload} />
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className="fw-bold">
                                            <RichMessage id="gift-card-details.details-card.body.reference" />
                                        </td>
                                        <td>{giftCard?.reference}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">
                                            <RichMessage id="gift-card-details.details-card.body.description" />
                                        </td>
                                        <td>{giftCard?.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">
                                            <RichMessage id="gift-card-details.details-card.body.sender" />
                                        </td>
                                        <td>{giftCard?.sender}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">
                                            <RichMessage id="gift-card-details.details-card.body.recipient" />
                                        </td>
                                        <td>{giftCard?.recipient}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">
                                            <RichMessage id="gift-card-details.details-card.body.expiration-date" />
                                        </td>
                                        <td>{moment(giftCard?.expirationDate).format('DD/MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">
                                            <RichMessage id="gift-card-details.details-card.body.amount" />
                                        </td>
                                        <td>{giftCard?.amount.toFixed(2)}€</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs="12" md="4" className="mb-3">
                    {/* Gift card > Usage */}
                    <Card className="h-100">
                        <Card.Header>
                            <FontAwesomeIcon className="me-2" icon={faBarsProgress} />
                            <span className="fw-bold fs-5">
                                <RichMessage id="gift-card-details.usage-card.header" />
                            </span>
                        </Card.Header>
                        <Card.Body>
                            {loadingUses ? (
                                <div className="text-center">
                                    <Spinner animation="border" variant="dark" />
                                </div>
                            ) : (
                                <>
                                    <table className="w-100 mb-3">
                                        <tbody>
                                            <tr>
                                                <td className="fw-bold">
                                                    <RichMessage id="gift-card-details.usage-card.body.total" />
                                                </td>
                                                <td>{giftCard.amount.toFixed(2)}€</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">
                                                    <RichMessage id="gift-card-details.usage-card.body.used" />
                                                </td>
                                                <td>{used.toFixed(2)}€</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">
                                                    <RichMessage id="gift-card-details.usage-card.body.remaining" />
                                                </td>
                                                <td>{remaining.toFixed(2)}€</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <ProgressBar now={usedPercentage} style={{ height: '2rem' }} />
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Gift card > History */}
            <Card className="mb-3">
                <Card.Header>
                    <FontAwesomeIcon className="me-2" icon={faClockRotateLeft} />
                    <span className="fw-bold fs-5">
                        <RichMessage id="gift-card-details.history-card.header" />
                    </span>
                </Card.Header>
                <Card.Body>
                    {loadingUses ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="dark" />
                        </div>
                    ) : (
                        <>
                            <div className="text-end mb-3">
                                <Button onClick={() => setIsAddGiftCardUseModalOpen(true)}>
                                    <FontAwesomeIcon className="me-2" icon={faPlus} />
                                    <RichMessage id="gift-card-details.history-card.add" />
                                </Button>
                            </div>
                            <Table
                                bordered
                                striped={!!uses?.length}
                                hover={!!uses?.length}
                                style={{ fontSize: '1rem' }}
                            >
                                <thead className="text-nowrap">
                                    <tr>
                                        <th>
                                            <RichMessage id="gift-card-details.history-card.table.th.date" />
                                        </th>
                                        <th>
                                            <RichMessage id="gift-card-details.history-card.table.th.amount" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uses?.length ? (
                                        uses?.map((e, index) => (
                                            <GiftCardUseRow giftCardId={giftCardId} use={e} onDelete={refetchUses} />
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-center text-muted" colSpan={2}>
                                                <RichMessage id="gift-card-details.history-card.table.empty" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Card.Body>
            </Card>

            {isAddGiftCardUseModalOpen && (
                <AddGiftCardUseModal
                    giftCard={giftCard}
                    isOpen={isAddGiftCardUseModalOpen}
                    onHide={() => setIsAddGiftCardUseModalOpen(false)}
                    onUseAdded={() => {
                        setIsAddGiftCardUseModalOpen(false);
                        refetchUses();
                    }}
                />
            )}
        </>
    );
};

export default GiftCardDetails;

import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { RichMessage } from '../../../components/RichMessage';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import useQuery from '../../../api/hooks/useQuery';
import {
    CreateCheckoutSessionRequestBody,
    CreateCheckoutSessionResponse,
} from '../../../api/models/create-checkout-session';
import { ProductsDropdown } from '../../../components/ProductsDropdown';
import { times } from 'lodash';
import useInitializationQuery from '../../../api/hooks/useInitializationQuery';
import { Product } from '../../../api/models/product';

// Group selected products by priceId and quantity
// Example: `groupPricesById(['price1', 'price1', 'price2'])` will return
// `[{ price: 'price1', quantity: 2 }, { price: 'price2', quantity: 1 }]`
const groupPricesById = (arr: (string | null)[]) => {
    const a = [];
    let prev;

    arr.sort();
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] == null) {
            continue;
        } else if (arr[i] !== prev) {
            a.push({ price: arr[i] as string, quantity: 1 });
        } else {
            a[a.length - 1].quantity++;
        }
        prev = arr[i];
    }

    return a;
};

const GiftCardForm: FunctionComponent = () => {
    // Services
    const intl = useIntl();

    // State
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

    // Fetch products
    const { data: products } = useInitializationQuery<Product[]>('get', '/products');

    // Form
    const [productsCount, setProductsCount] = useState<number>(1);
    const [selectedProducts, setSelectedProducts] = useState<(string | null)[]>(['']);
    const [sender, setSender] = useState<string>('');
    const [recipient, setRecipient] = useState<string>('');
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    // Compute total
    const total = useMemo(
        () =>
            selectedProducts.reduce((sum: number, e: string | null) => {
                // Retrieve product from `priceId`
                const product = products?.find((p) => p.priceId === e);

                // Abort if not found
                if (!product) return sum;

                // Accumulate product price
                return (sum += product.amount);
            }, 0),
        [products, selectedProducts]
    );

    // Create checkout session
    const createCheckoutSession = useQuery<CreateCheckoutSessionRequestBody, CreateCheckoutSessionResponse>(
        'post',
        '/purchase-session'
    );

    // Form validation
    const isFormValid = useMemo(
        () => selectedProducts.every((e) => !!e) && !!sender && !!recipient && termsAccepted,
        [recipient, selectedProducts, sender, termsAccepted]
    );

    // Hook invoked when a product is selected from a dropdown menu
    const handleProductSelect = useCallback(
        (e: string | null, index: number) => {
            // Abort if submit is in progress
            if (submitInProgress) return;

            // Build updated products
            const updatedProducts = [...selectedProducts];
            updatedProducts[index] = e;

            // Update state
            setSelectedProducts(updatedProducts);
        },
        [selectedProducts, submitInProgress]
    );

    // Hook invoked when adding a product to the form
    const handleAddProductFormControl = useCallback(() => {
        // Abort if submit is in progress
        if (submitInProgress) return;

        // Build updated products
        const updatedProducts = selectedProducts.concat('');

        // Update selected products
        setSelectedProducts(updatedProducts);

        // Update products count
        setProductsCount((e) => e + 1);
    }, [selectedProducts, submitInProgress]);

    // Hook invoked when removing a product to the form
    const handleRemoveProductFormControl = useCallback(
        (index: number) => {
            // Abort if submit is in progress
            if (submitInProgress) return;

            // Build updated products
            const updatedProducts = [...selectedProducts];
            updatedProducts.splice(index, 1);

            // Update selected products
            setSelectedProducts(updatedProducts);

            // Update products count
            setProductsCount((e) => e - 1);
        },
        [selectedProducts, submitInProgress]
    );

    // Hook invoked when form is submitted
    const handleSubmit = useCallback(async () => {
        // Set loading state
        setSubmitInProgress(true);

        // Request checkout session to server
        const res = await createCheckoutSession({
            body: {
                recipient,
                sender,
                type: 'products',
                details: groupPricesById(selectedProducts),
            },
        });

        if (res?.data) {
            // Redirect to checkout
            const stripePromise = await loadStripe(res?.data.publicKey);
            await stripePromise?.redirectToCheckout({ sessionId: res?.data.id });
        }

        // Reset loading state
        setSubmitInProgress(false);
    }, [createCheckoutSession, recipient, selectedProducts, sender]);

    return (
        <div className="m-auto" style={{ maxWidth: 600 }}>
            {/* Title */}
            <h4 className="fw-bold mb-4">
                <RichMessage id="gift-card.form.title" />
            </h4>

            {/* Form */}
            <Form>
                {/* Product(s) selection */}
                <Form.Group className="mb-3" controlId="giftCardForm.Products">
                    <Form.Label>
                        <RichMessage id="gift-card.form.products.label" />
                    </Form.Label>

                    {times(productsCount, (e) => (
                        <div key={e} className="d-flex mb-3">
                            {/* Products dropdown */}
                            <ProductsDropdown
                                className="flex-grow-1"
                                selected={selectedProducts[e]}
                                onSelect={(p) => handleProductSelect(p, e)}
                            />

                            {/* Remove product button */}
                            {productsCount > 1 && (
                                <Button
                                    size="sm"
                                    variant="dark"
                                    className="ms-2 flex-grow-0"
                                    disabled={submitInProgress}
                                    onClick={() => handleRemoveProductFormControl(e)}
                                >
                                    <FontAwesomeIcon icon={faRemove} />
                                </Button>
                            )}
                        </div>
                    ))}

                    {/* Add product button */}
                    {selectedProducts.length < 4 && (
                        <Button variant="dark" onClick={handleAddProductFormControl}>
                            <FontAwesomeIcon className="me-2" icon={faPlus} />
                            <RichMessage id="gift-card.form.products.add" />
                        </Button>
                    )}
                </Form.Group>

                {/* Recipient */}
                <Form.Group className="mb-3" controlId="giftCardForm.Recipient">
                    <Form.Label>
                        <RichMessage id="gift-card.form.recipient.label" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={intl.formatMessage({ id: 'gift-card.form.recipient.placeholder' })}
                        disabled={submitInProgress}
                        value={recipient}
                        onChange={(e) => setRecipient(e.target.value)}
                    />
                </Form.Group>

                {/* Sender */}
                <Form.Group className="mb-3" controlId="giftCardForm.Sender">
                    <Form.Label>
                        <RichMessage id="gift-card.form.sender.label" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={intl.formatMessage({ id: 'gift-card.form.sender.placeholder' })}
                        disabled={submitInProgress}
                        value={sender}
                        onChange={(e) => setSender(e.target.value)}
                    />
                </Form.Group>

                {/* Total */}
                <Form.Group className="mb-2" controlId="giftCardForm.total">
                    <Form.Label>
                        <RichMessage id="gift-card.form.total.label" values={{ total }} />
                    </Form.Label>
                </Form.Group>

                {/* Accept terms */}
                <Form.Group className="mb-4" controlId="giftCardForm.AcceptTerms">
                    <Form.Check
                        type="checkbox"
                        label={<RichMessage id="gift-card.form.accept-terms.label" />}
                        disabled={submitInProgress}
                        checked={termsAccepted}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                    />
                </Form.Group>

                {/* Submit button */}
                <div className="text-center">
                    <Button
                        className="px-4 py-2 fw-bold fs-5"
                        type="submit"
                        disabled={!isFormValid || submitInProgress}
                        onClick={handleSubmit}
                    >
                        <FontAwesomeIcon className="me-2" icon={faCreditCard} />
                        {submitInProgress ? (
                            <RichMessage id="gift-card.form.submitting" />
                        ) : (
                            <RichMessage id="gift-card.form.submit" />
                        )}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default GiftCardForm;

const mainMessages = {
    // -----------------------------------
    // Components
    // -----------------------------------
    'components.confirmation-modal.confirm': 'Confirmer',
    'components.confirmation-modal.cancel': 'Annuler',

    // -----------------------------------
    // Public
    // -----------------------------------
    /* Public navbar */
    'public-navbar.home': 'Oh My Nails!',
    'public-navbar.prestations.title': 'Prestations',
    'public-navbar.prestations.onglerie': 'Onglerie',
    'public-navbar.prestations.microblading': 'Microblading',
    'public-navbar.prestations.rehaussement-de-cils': 'Réhaussement de cils',
    'public-navbar.prestations.soins-du-visage': 'Soins du visage',
    'public-navbar.prestations.epilation': 'Epilation',
    'public-navbar.prestations.arganicare': 'Arganicare',
    'public-navbar.tarifs.title': 'Tarifs',
    'public-navbar.tarifs.onglerie': 'Onglerie',
    'public-navbar.tarifs.soins-du-visage': 'Soins du visage',
    'public-navbar.tarifs.epilation': 'Epilation',
    'public-navbar.tarifs.beaute-du-regard': 'Beauté du regard',
    'public-navbar.tarifs.microblading': 'Microblading',
    'public-navbar.tarifs.maquillage': 'Maquillage',
    'public-navbar.tarifs.galerie-photos': 'Galerie photos',
    'public-navbar.tarifs.cheque-cadeau': 'Offrir un chèque cadeau',
    'public-navbar.tarifs.contact': 'Contact',

    /* Footer */
    'footer.contact': 'Contact',
    'footer.legal-notice': 'Mentions légales',
    'footer.terms': 'Conditions générales de vente',

    /* Home */
    'home.text':
        "« Oh my nails ! » un institut privé chic et tendance entièrement dédié à votre beauté jusque dans les moindres détails !{break2}Spécialisé en onglerie, maquillage semi-permanent et beauté du regard, l'institut « Oh my nails ! » s'adresse à toutes celles en quête de perfection et de tranquillité: de beaux ongles parfaitement manucurés pendant plusieurs semaines, des sourcils parfaitement dessinés au rendu ultra naturel, un regard intense et stylé qui vous dispense de vous maquiller tous les jours…{break2}L'institut « Oh my nails ! » vous propose des prestations haut de gamme avec des produits de qualité ainsi qu'un conseil personnalisé afin de répondre précisément à vos attentes.",

    /* Prestations */
    'prestations.onglerie.title': "L'onglerie, embellissez vos mains et vos pieds !",
    'prestations.onglerie.body':
        "Pour un renforcement solide de vos ongles ainsi qu'un effet durable et résistant aux chocs, optez pour le gel ! La pose de gel couleur ou french se fait sur ongles naturels ou sur extensions en gel (pas de colle ni de capsules). La tenue est de 4 semaines en moyenne.{break2}Pour des ongles courts et vernis durablement, choisissez le semi-permanent ! La couleur ou la french se fait sur ongles naturels uniquement, pas d'extension possible. La tenue est de 3 semaines en moyenne. Cette prestation est tout à fait adaptée pour les pieds (tenue 6 à 8 semaines).",
    'prestations.microblading.title': 'Le microblading, la pigmentation pour des sourcils plus vrais que nature !',
    'prestations.microblading.body':
        "Plutôt que de vous maquiller les sourcils chaque matin, pourquoi ne pas opter pour cette toute nouvelle technique de maquillage semi-permanent au résultat longue durée et surtout bluffant de naturel ?{break2}Issu d'une gestuelle asiatique ancestrale, le microblading, aussi appelée technique 6D, est un maquillage semi-permanent qui permet de restructurer un sourcil de manière « plus vraie que nature ». Cette calligraphie des sourcils est réalisée à l'aide d'un stylo métallique et de nano aiguilles réunies sur une même ligne qui forment une fine lame. Cela permet d'obtenir une réplique parfaite du sourcil naturel idéal en une heure de temps seulement. Après la première séance, une séance de retouche est nécessaire environ trois semaines après pour fixer le travail de pigmentation.{break2}Le microblading est durable dans le temps et s'estompe tout en douceur au bout d'un an et demi environ. Cette technique, plus superficielle que le maquillage permanent, n'abîme pas les capillaires, ne touche pas les bulbes et n'altère pas la repousse des poils.{break2}Et puisque les aiguilles pénètrent moins profondément sous la peau, elles touchent moins de terminaisons nerveuses et la douleur est donc très supportable. De même on constate généralement moins de saignement. La cicatrisation n'en est que plus rapide. Pas de petites croûtes gênantes dans les jours qui suivent la prestation!    ",
    'prestations.rehaussement-de-cils.title': 'Le réhaussement de cils, un vrai regard de biche, jour et nuit !',
    'prestations.rehaussement-de-cils.body':
        "Contrairement à la permanente, cette technique permet avant tout d'ouvrir l'œil en courbant les cils dès la racine et non pas seulement sur la longueur. Les cils restent souples et paraissent beaucoup plus longs. Ce soin est particulièrement recommandé aux cils courts.{break2}Le résultat est de longue durée : 2 à 3 mois, soit la durée de vie moyenne d'un cil. Le réhaussement n'empêche pas de porter du mascara, même tous les jours (attention tout de même à démaquiller en douceur pour ne pas arracher les cils) et est bien évidemment waterproof. Malgré le traitement chimique, les cils ne tombent pas prématurément et repoussent aussi forts qu'avant, voire plus.{break2}Le must reste d'associer un réhaussement et une teinture de cils pour être totalement tranquille !",
    'prestations.soins-du-visage.title': 'Soins du visage',
    'prestations.soins-du-visage.body':
        "Chouchoutez votre peau quelle que soit la saison et profitez d'un véritable moment de détente!{break2}Soins du visage sur mesure, adaptés aux besoins de votre peau et entièrement réalisés avec les produits haut de gamme Arganicare.{break2}Nettoyage en profondeur, hydratation et éclat pour une peau lissée et unifiée.",
    'prestations.epilation.title': "L'épilation, halte aux poils",
    'prestations.epilation.body':
        "Pour une sortie à la plage, une nouvelle petite jupe d'été ou le plaisir simple de se sentir douce, nous passons toutes par ce petit soin beauté pas si insurmontable que ça !{break2}Cire tiède pour vos jambes et cire chaude pour les aisselles, le maillot ou encore le visage, deux techniques professionnelles incontournables pour venir à bout de vos poils mêmes les plus courts.",
    'prestations.arganicare.title': 'Arganicare',
    'prestations.arganicare.body':
        "Une ligne de soin visage et corps conçue pour nourrir et hydrater votre peau en profondeur. Enrichie en huile d'argan bio, elle est conçue pour traiter tous les types de peaux, très sèches, sensibles, mixtes à grasses. Sa formulation douce est infusée avec des ingrédients actifs avancés, tels que des vitamines, des minéraux et des peptides. Ces éléments fournissent des soins délicats pour la peau, ils la restaurent et la revitalisent. L'huile d'argan est facilement absorbée dans l'épiderme, sa texture non grasse et non irritante fait d'elle un composant idéal pour hydrater et adoucir la peau.{break2}La gamme Skin Care propose des soins enrichis en huile d'argan bio, aloe vera pur, beurre de karité, calendula, vitamines A et E. Un complexe hydratant unique qui protège votre peau des agressions extérieures. La gamme Collagen Boost propose des soins riches en collagène et huile d'argan bio afin d'hydrater intensément, raffermir votre peau et réduire les signes de l'âge..{break2}Résultat : Votre peau est lumineuse et pleine de santé, votre teint frais et resplendissant d'éclat!",

    /* Products dropdown */
    'components.products-dropdown.placeholder': 'Sélectionnez une prestation',

    /* Gift card */
    'gift-card.title': 'Chèque cadeau',
    'gift-card.description':
        "Vous souhaitez offrir un chèque cadeau et faire plaisir à coup sûr?{break2}Rien de plus simple ! Vous pouvez le faire de chez vous et recevoir votre bon par mail pour ensuite l'imprimer et l'offrir !{break2}Cliquez ci-dessous et laissez-vous guider !",
    'gift-card.button': 'Offrir un chèque cadeau',

    /* Gift card > Form */
    'gift-card.form.title': 'Offrir un chèque cadeau',
    'gift-card.form.products.label': 'Prestation(s)',
    'gift-card.form.products.add': 'Ajouter une autre prestation',
    'gift-card.form.recipient.label': "A l'attention de",
    'gift-card.form.recipient.placeholder': 'Prénom / Nom',
    'gift-card.form.sender.label': 'De la part de',
    'gift-card.form.sender.placeholder': 'Prénom / Nom',
    'gift-card.form.total.label': 'Total : {total}€',
    'gift-card.form.accept-terms.label': "J'accepte les <link-to-terms>conditions générales de vente</link-to-terms>",
    'gift-card.form.submit': 'Payer en ligne',
    'gift-card.form.submitting': 'Payer en ligne...',

    /* Gift card > Form > Success */
    'gift-card.form.success.title': 'Succès !',
    'gift-card.form.success.description': 'Paiement réussi !',
    'gift-card.form.success.description2':
        'Votre commande a été traitée avec succès.{break}Le chèque cadeau vient de vous être envoyé par e-mail.',

    /* Gift card > Form > Canceled */
    'gift-card.form.canceled.title': 'Échec !',
    'gift-card.form.canceled.description': 'Paiement annulé !',
    'gift-card.form.canceled.description2': 'Votre paiement a échoué ou a été annulé.{break}Veuillez réessayer.',

    /* Contact */
    'contact.title': 'Contact',
    'contact.description':
        'Institut de beauté privé sur Quimper.{break}Uniquement sur rendez-vous.{break}Contact par téléphone, sms, mail ou réseaux sociaux.',
    'contact.phone': '07 81 00 35 79',
    'contact.phone.href': '0781003579',
    'contact.email': 'ohmynailsquimper@gmail.com',
    'contact.email.href': 'ohmynailsquimper@gmail.com',
    'contact.social.facebook': '/ohmynailsquimper',
    'contact.social.facebook.href': 'http://www.facebook.com/ohmynailsquimper/',
    'contact.social.insta': '/oh_my_nails_quimper',
    'contact.social.insta.href': 'http://www.instagram.com/oh_my_nails_quimper/',

    /* Terms */
    'terms.title': 'Conditions générales de ventes',
    'terms.description': `
    <b>ARTICLE 1 - Champs d'application et objet des services en ligne</b>{break2}
    Les présentes conditions générales de vente s'appliquent, sans restriction ni réserve à l'ensemble des services et prestations proposés par la société Oh My Nails Quimper, sur son site Internet et à destination des clients.{break2}
    A défaut, l'utilisation des services d'achat de chèque cadeau en ligne est interdite.{break2}
    Pour accepter les conditions générales de vente, le client est invité à cocher la case correspondante lors du processus d'achat de chèque cadeau en ligne, telle que décrite à l'Article 2 des présentes.{break2}
    Par l'intermédiaire de son site Internet, la société Oh My Nails Quimper offre au client la possibilité d'acheter en ligne des chèques cadeaux parmi une gamme de soins esthétiques et de beauté.{break2}
    En conséquence, le fait pour une personne physique ou morale, de procéder à un achat de chèque cadeau en ligne sur le site Internet de la société Oh My Nails Quimper emporte acceptation pleine et entière des présentes conditions générales de vente.{break2}
    Elles sont accessibles à tout moment sur ce site Internet et prévaudront, le cas échéant, sur toute autre version, étant précisé que seule la version en vigueur lors de la commande du Client lui sera opposable.{break2}
    <b>ARTICLE 2 - Modalités et achat de chèque cadeau sur le site Internet</b>{break2}
    Afin d'acheter un chèque cadeau en ligne sur le site, le client doit renseigner son nom, son prénom et son adresse e-mail.{break2}
    Le client choisit entre la sélection d'une ou plusieurs prestations parmi la liste présentée, ou d'offrir un montant qui pourra être dépensé en une ou plusieurs fois dans le magasin. Le client accepte ensuite les présentes CGV en cochant la case prévue à cet effet.{break2}
    Le client recevra ensuite un email de confirmation comprenant les détails de sa commande, ainsi que le chèque cadeau généré en pièce jointe.{break2}
    En application du droit en vigueur, lorsque le client souhaite annuler un chèque cadeau acheté en ligne, il dispose d'un délai légal de 14 jours à compter de sa commande pour se manifester auprès de la société Oh My Nails Quimper, sous réserve de ne pas avoir commencé à recevoir la prestation commandée pendant ce délai.{break2}
    <b>ARTICLE 3 - Paiement du prix</b>{break2}
    La fourniture des prestations des chèques cadeaux est réalisée contre paiement par le client du prix total correspondant à la ou les prestation(s) choisie(s), selon les modalités établies dans l'Établissement Oh My Nails Quimper.{break2}
    Le prix est payable selon l'offre et en un seul versement à la commande et vaut confirmation de commande.{break2}
    Les prix indiqués sur le site Internet sont exprimés en Euros et TTC et correspondent à ceux en vigueur au jour de la commande.{break2}
    La société Oh My Nails Quimper se réserve le droit de modifier les prix à tout moment, le prix indiqué dans la confirmation de la commande étant le prix en vigueur.{break2}
    Le paiement ne sera considéré comme définitif qu'après encaissement effectif des fonds par la société Oh My Nails Quimper.{break2}
    Le paiement en ligne est délégué à la société Stripe, qui assure la sécurité des transactions. Le règlement des achats s'effectue par les moyens supportés par Stripe Checkout.{break2}
    A aucun moment Oh My Nails Quimper est destinataire ni n'a accès aux coordonnées bancaires qui sont seulement collectées et éventuellement conservées par le prestataire de paiement, Stripe.{break2}
    <b>ARTICLE 4 - Informatiques et Libertés</b>{break2}
    En application de la loi n° 78-17 du 6 janvier 1978 modifiée par la loi n° 2018-493 du 20 juin 2018 et le Règlement UE no 2016/679 “RGPD”, il est rappelé que les données nominatives qui sont demandées au client sont nécessaires au traitement de sa commande et sont destinées à un usage interne.{break2}
    Ces données nominatives peuvent néanmoins être transmises à des tiers, prestataires ou sous réserve d'un consentement exprès, partenaires de la société Oh My Nails Quimper.{break2}
    Conformément à cette législation et au RGPD, tout Client bénéficie d'un droit d'accès, d'information complémentaire, de rectification, de portabilité, de limitation, de suppression et d'opposition aux données personnelles le concernant.{break2}
    <b>ARTICLE 5 - Droit applicable</b>{break2}
    Les présentes Conditions générales sont régies exclusivement par le droit français et compétence territoriale exclusive est donnée aux juridictions françaises.{break2}
    En cas de litige survenant dans le cadre des prestations sans résolution directe avec Oh My Nails Quimper, le client aura la possibilité, avant toute action en justice, de rechercher une solution amiable, en saisissant un médiateur de la consommation, le cas échéant avec l'aide d'une association de consommateurs ou de tout autre conseil de son choix.{break2}
    Tout consommateur qui rencontre un litige avec une entreprise située sur le territoire de l'Union a la possibilité de déposer une demande de médiation par le biais de cette plateforme européenne. {break2}
    `,

    /* Legal notice */
    'legal-notice.title': 'Mentions légales',
    'legal-notice.description': `
    <b>Coordonnées</b>{break2}
    Dénomination: KORE Charlotte. Enseigne : Oh My Nails - Quimper{break}
    Adresse: 5 impasse de Coz Maner 29000 Quimper{break}
    Numéro d'immatriculation au répertoire des métiers: 530.477.702 RM 29{break}
    Email: ohmynailsquimper@gmail.com{break}
    Téléphone: 07 81 00 35 79{break2}
    <b>Hébergeur</b>{break2}
    Dénomination: BAILLEUL Jérémie (développeur indépendant){break}
    Email: jeremie.bailleul@gmail.com{break2}
    <b>Politique de gestion des données personnelles</b>{break2}
    Nous vous rappelons que la loi fixe la majorité numérique à l'âge de 15 ans. Conformément à l'article 7-1 de la loi n°18-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, un mineur à compter de l'âge de 15 ans peut consentir seul à un traitement de données à caractère personnel.{break2}
    Conformément à l'article 13 du Règlement du Parlement européen et du Conseil relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE politique de protection des données à caractère personnel, la présente politique a pour objet de vous informer sur la manière dont sont traitées les données à caractère personnel que vous partagez avec nous{break2}
    1. Nom et adresse du responsable du traitement{break}
    Le responsable du traitement de vos données, au sens de la réglementation sur la protection des données, est l'hébergeur, joignable aux fins des présentes à l'adresse électronique suivante : jeremie.bailleul@gmail.com{break2}
    2. Finalités du traitement{break}
    Les données personnelles suivantes sont collectées et stockées uniquement lorsque vous procédez à l'achat d'un chèque cadeau en ligne :{break}
    - Nom/prénom/e-mail de l'émetteur du chèque cadeau{break}
    - Nom/prénom du destinataire du chèque cadeau{break2}
    3. La base légale du traitement{break}
    Nous utilisons vos données pour pouvoir construire et vous envoyer votre chèque cadeau numérique{break2}
    4. Sécurité et confidentialité des données{break}
    Vos données sont stockées sur un serveur privé dont seul l'hébergeur dispose d'un droit d'accès. Le(s) employé(s) d'Oh My Nails disposent également d'un droit de consultation de ces données, dans le but de pouvoir vérifier et authentifier un chèque cadeau acheté en ligne.{break2}
    5. Vos droits relatifs aux traitements de vos données{break}
    Conformément à la loi n°78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un droit d'accès, de rectification, d'effacement de vos données personnelles, d'en demander la portabilité, ou la limitation de leur traitement{break2}
    <b>Cookies</b>{break2}
    Le paiement en ligne de chèques cadeaux étant assuré par la société Stripe, des cookies en provenance de stripe.com sont ajoutés automatiquement à votre navigateur, pour assurer le bon fonctionnement du paiement en ligne.{break2}
    Pour plus d'informations sur les cookies Stripe, vous pouvez vous référer à leur site officiel.{break2}
    `,

    // -----------------------------------
    // Admin
    // -----------------------------------
    /* Admin navbar */
    'admin-navbar.login': 'Connexion',
    'admin-navbar.logout': 'Se déconnecter',
    'admin-navbar.gift-cards': 'Chèques cadeaux',

    /* Login */
    'login.title': 'Connexion',
    'login.form.username.label': "Nom d'utilisateur",
    'login.form.password.label': 'Mot de passe',
    'login.form.submit': 'Se connecter',
    'login.form.submitting': 'Connexion en cours...',

    /* Gift cards */
    'gift-cards.title': 'Chèques cadeaux',

    /* Gift cards > Filters */
    'gift-cards.filters.search.placeholder': 'Recherche',
    'gift-cards.filters.hide-archived.label': 'Masquer les chèques cadeaux archivés',
    'gift-cards.add': 'Ajouter',
    'gift-cards.manage': 'Gérer',
    'gift-cards.archive': 'Archiver {count, plural, =0 {} other {({count})}}',
    'gift-cards.delete': 'Supprimer {count, plural, =0 {} other {({count})}}',

    /* Gift cards > List */
    'gift-cards.table.th.reference': 'Référence',
    'gift-cards.table.th.description': 'Description',
    'gift-cards.table.th.sender': 'Emetteur',
    'gift-cards.table.th.recipient': 'Destinataire',
    'gift-cards.table.th.expiration-date': "Date d'expiration",
    'gift-cards.table.th.amount': 'Montant',
    'gift-cards.table.th.usage': 'Utilisation',
    'gift-cards.table.th.details': 'Détails',
    'gift-cards.table.td.description.amount': 'Montant',
    'gift-cards.table.empty': 'Aucun résultat',

    /* Gift cards > Archive confirmation modal */
    'gift-cards.archive-modal.header': 'Archivage',
    'gift-cards.archive-modal.body':
        "Vous êtes sur le point d'archiver le(s) chèque(s) cadeau(x) sélectionné(s).{break2}Souhaitez-vous poursuivre ?",
    'gift-cards.archive-modal.footer.archive': 'Archiver',
    'gift-cards.archive-modal.footer.archiving': 'Archivage en cours...',

    /* Gift cards > Deletion confirmation modal */
    'gift-cards.deletion-modal.header': 'Suppression',
    'gift-cards.deletion-modal.body':
        'Vous êtes sur le point de supprimer le(s) chèque(s) cadeau(x) sélectionné(s).{break2}Souhaitez-vous poursuivre ?',
    'gift-cards.deletion-modal.footer.delete': 'Supprimer',
    'gift-cards.deletion-modal.footer.deleting': 'Suppression en cours...',

    /* Gift cards > Add gift card modal */
    'add-gift-card-modal.header': 'Ajouter un chèque cadeau',
    'add-gift-card-modal.body.form.type.label': 'Type',
    'add-gift-card-modal.body.form.type.placeholder': 'Sélectionnez un type',
    'add-gift-card-modal.body.form.type.amount': 'Montant',
    'add-gift-card-modal.body.form.type.products': 'Prestation',
    'add-gift-card-modal.body.form.amount.label': 'Montant',
    'add-gift-card-modal.body.form.products.label': 'Prestation',
    'add-gift-card-modal.body.form.sender.label': 'Emetteur',
    'add-gift-card-modal.body.form.recipient.label': 'Destinataire',
    'add-gift-card-modal.body.form.expiration-date.label': "Date d'expiration",
    'add-gift-card-modal.footer.cancel': 'Annuler',
    'add-gift-card-modal.footer.add': 'Ajouter',
    'add-gift-card-modal.footer.adding': 'Ajout en cours...',

    /* Gift card details */
    'gift-card-details.back': 'Revenir à la liste',

    /* Gift card details > Details card */
    'gift-card-details.details-card.title': 'Chèque cadeau',
    'gift-card-details.details-card.header': 'Détails',
    'gift-card-details.details-card.body.reference': 'Référence',
    'gift-card-details.details-card.body.description': 'Description',
    'gift-card-details.details-card.body.sender': 'Emetteur',
    'gift-card-details.details-card.body.recipient': 'Destinataire',
    'gift-card-details.details-card.body.expiration-date': "Date d'expiration",
    'gift-card-details.details-card.body.amount': 'Montant',

    /* Gift card details > Usage card */
    'gift-card-details.usage-card.header': 'Utilisation',
    'gift-card-details.usage-card.body.total': 'Total',
    'gift-card-details.usage-card.body.used': 'Utilisé',
    'gift-card-details.usage-card.body.remaining': 'Restant',

    /* Gift card details > History card */
    'gift-card-details.history-card.header': 'Historique',
    'gift-card-details.history-card.add': 'Ajouter',
    'gift-card-details.history-card.table.th.date': 'Date',
    'gift-card-details.history-card.table.th.amount': 'Montant utilisé',
    'gift-card-details.history-card.table.empty': 'Aucune utilisation',

    /* Gift cards details > Add use modal */
    'gift-card-details.add-use-modal.header': 'Ajouter une utilisation',
    'gift-card-details.add-use-modal.body.form.date.label': 'Date',
    'gift-card-details.add-use-modal.body.form.amount.label': 'Montant',
    'gift-card-details.add-use-modal.footer.cancel': 'Annuler',
    'gift-card-details.add-use-modal.footer.add': 'Ajouter',
    'gift-card-details.add-use-modal.footer.adding': 'Ajout en cours...',
};

export default mainMessages;

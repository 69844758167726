import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSafeState } from '../../util/useSafeState';
import { AxiosInstance } from '../AxiosInstance';
import { useHandleError } from './useHandleError';
import { AuthContext } from '../../auth/AuthContext';

const useInitializationQuery = <T>(
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    params?: AxiosRequestConfig
) => {
    // State
    const [data, setData] = useState<T | undefined>(undefined);
    const [loading, setLoading] = useSafeState(false);
    const [error, setError] = useState<string | null>(null);

    // Context
    const { token } = useContext(AuthContext);

    // Handle error
    const handleError = useHandleError();

    // Hook invoked to fetch data
    const fetchData = useCallback(async () => {
        try {
            // Set loading state
            setLoading(true);

            // Execute query
            const response: AxiosResponse<T> = await AxiosInstance[method](url, {
                ...params,
                ...(token
                    ? {
                          headers: { Authorization: `Bearer ${token}` },
                      }
                    : {}),
            });

            // Set data state
            setData(response.data);
        } catch (error: any) {
            // Set error
            setError('Error getting the data');
            handleError(error);
        } finally {
            // Reset loading state
            setLoading(false);
        }
    }, [handleError, method, params, setLoading, token, url]);

    // Fetch data
    useEffect(() => {
        fetchData();
    }, [fetchData, handleError, method, params, setLoading, token, url]);

    return { data, loading, error, refetch: fetchData };
};

export default useInitializationQuery;

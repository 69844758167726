import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { RICH_TAGS } from './RichMessageConfig';
import { FormattedMsgValues, useRichIntl } from './useRichIntl';

export interface FormattedMsgDescriptor {
    id: string;
    values?: FormattedMsgValues;
}

export interface RichMessageProps extends FormattedMsgDescriptor {
    fallbackId?: string;
}

// Wrapper component used to inject common HTML tags in translations
// This allows us to define a kind of "meta language" for translation keys
// with commonly used HTML tags (bold, italic, pre etc.)
export const RichMessage: FunctionComponent<RichMessageProps> = ({ id, fallbackId, values }: RichMessageProps) => {
    // Services
    const intl = useRichIntl();

    // Context
    const msgContext = { ...RICH_TAGS, ...values };

    // Render
    if (!fallbackId || intl.messages[id]) {
        // If the message exists or there is no fallback any, then proceed with the message
        return <FormattedMessage id={id} values={msgContext} />;
    } else {
        // Use the fallback message if the target message does not exist
        return <FormattedMessage id={fallbackId} values={msgContext} />;
    }
};

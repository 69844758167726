import { ReactNode, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

export const RICH_TAGS = {
    break: <br />,
    space: <span className="ms-1"></span>,
    faArrowRight: <FontAwesomeIcon icon={faLongArrowAltRight} className="mx-1" />,
    break2: (
        <Fragment>
            <br />
            <br />
        </Fragment>
    ),
    b: (chunks: ReactNode) => <b>{chunks}</b>,
    em: (chunks: ReactNode) => <em>{chunks}</em>,
    pre: (chunks: ReactNode) => <pre className="d-inline text-light-purple fw-bold">{chunks}</pre>,
    u: (chunks: ReactNode) => <span className="text-decoration-underline">{chunks}</span>,
    appositive: (chunks: ReactNode) => <span className="fs-5 fw-500">{chunks}</span>,
    'fw-light': (chunks: ReactNode) => <span className="fw-light">{chunks}</span>,
    'text-muted': (chunks: ReactNode) => <span className="text-muted">{chunks}</span>,
    'text-light': (chunks: ReactNode) => <span className="text-light">{chunks}</span>,
    'text-light-turquoise': (chunks: ReactNode) => <span className="text-light-turquoise">{chunks}</span>,
    'text-grey-4': (chunks: ReactNode) => <span className="text-grey-4">{chunks}</span>,
    'text-danger': (chunks: ReactNode) => <span className="text-danger">{chunks}</span>,
    'text-warning': (chunks: ReactNode) => <span className="text-warning">{chunks}</span>,
    'text-success': (chunks: ReactNode) => <span className="text-success">{chunks}</span>,
    'text-secondary': (chunks: ReactNode) => <span className="text-secondary">{chunks}</span>,
    'text-primary': (chunks: ReactNode) => <span className="text-primary">{chunks}</span>,
    'link-to-terms': (chunks: ReactNode) => (
        <Link to="/cgv" target="_blank" rel="noreferrer" className="hover-underline text-primary">
            {chunks}
        </Link>
    ),
};

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface Props {
    isOpen: boolean;
    isCloseDisabled?: boolean;
    icon?: IconDefinition;
    iconClassname?: string;
    title: ReactNode;
    content: ReactNode;
    customConfirmButton?: ReactNode;
    onCancel: () => void;
    onConfirm?: () => void;
}

export const ConfirmationModal: FunctionComponent<Props> = ({
    isOpen,
    isCloseDisabled,
    icon,
    iconClassname,
    title,
    content,
    customConfirmButton,
    onCancel,
    onConfirm,
}: Props) => {
    return (
        <Modal backdrop={isCloseDisabled ? 'static' : true} show={isOpen} onHide={onCancel}>
            <Modal.Header closeButton={!isCloseDisabled}>
                <Modal.Title>
                    {/* Icon */}
                    {icon && <FontAwesomeIcon className={classNames('me-3', iconClassname)} icon={icon} />}

                    {/* Title */}
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5 pb-3">{content}</Modal.Body>
            <Modal.Footer>
                {/* Cancel button */}
                <Button variant="outline-dark" disabled={isCloseDisabled} onClick={onCancel}>
                    <FormattedMessage id="components.confirmation-modal.cancel" />
                </Button>

                {/* Confirm button, either the default synchronous one, or a custom one for asynchronous */}
                {customConfirmButton || (
                    <Button variant="danger" onClick={onConfirm}>
                        <FormattedMessage id="components.confirmation-modal.confirm" />
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

import { FunctionComponent, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AdminNavbar from '../../components/Navbar/AdminNavbar';

const Layout: FunctionComponent = () => {
    // Refs
    const ref = useRef<HTMLElement>(null);

    // Services
    const { pathname } = useLocation();

    // Scroll to top on route change
    useEffect(() => {
        ref.current?.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="d-flex flex-column h-100 w-100">
            <AdminNavbar className="flex-grow-0" />
            <main className="flex-grow-1 overflow-y-auto" style={{ minWidth: 0 }}>
                <div className="p-4 p-sm-5">
                    <div className="w-100 h-100 mx-auto bg-transparent" style={{ maxWidth: 1000 }}>
                        <Outlet />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Layout;

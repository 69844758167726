import classNames from 'classnames';
import { RichMessage, RichMessageProps } from 'components/RichMessage';
import React, { FunctionComponent } from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';

interface Props extends ButtonProps {
    messageProps: RichMessageProps;
    loading: boolean;
    loadingMessageProps: RichMessageProps;
}

export const AsyncButton: FunctionComponent<Props> = (props: Props) => {
    const { messageProps, loading, loadingMessageProps, ...buttonProps } = props;

    return (
        <Button {...buttonProps} className={classNames('text-nowrap', buttonProps.className)}>
            {loading ? (
                <>
                    <Spinner className="me-2" animation="border" size="sm" />
                    <RichMessage {...loadingMessageProps} />
                </>
            ) : (
                <RichMessage {...messageProps} />
            )}
        </Button>
    );
};

import { FunctionComponent } from 'react';
import { AppRoutes } from './routes/AppRoutes';

// CSS
import 'assets/sass/main.scss';

const App: FunctionComponent = () => {
    return <AppRoutes />;
};

export default App;
